import { style, animate, trigger, transition } from '@angular/animations';

export const showHideAnimation = trigger('showHideAnimation', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('0.3s', style({ opacity: 1 }))
    ]),
    transition(':leave', [
        style({ opacity: 1 }),
        animate('0.3s', style({ opacity: 0 }))
    ])
]);

