import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import ImageEditor from 'tui-image-editor';
import { environment as config } from '../../../../../environments/environment';

export interface IImageEditorDialogData {
  imageUrl: string;
}

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor-dialog.component.html',
  styleUrls: ['./image-editor-dialog.component.scss']
})
export class ImageEditorDialogComponent implements AfterViewInit, OnDestroy {
  editorInstance: ImageEditor;
  theme = whiteTheme;
  unlisteners: (() => void)[] = [];

  @ViewChild('imageEditor', { static: true }) editorRef: ElementRef;

  constructor(
    private componentElement: ElementRef,
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<ImageEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IImageEditorDialogData) { }

  ngAfterViewInit(): void {
    const imagePath = `${config.fetchFileUrl}?url=${encodeURIComponent(this.data.imageUrl)}`;
    const options = {
      includeUI: {
        loadImage: {
          path: imagePath,
          name: 'Edit Media Image'
        },
        theme: this.theme,
        menuBarPosition: 'bottom',
      },
      cssMaxWidth: 1000,
      cssMaxHeight: 300,
      usageStatistics: false
    };
    this.editorInstance = new ImageEditor(this.editorRef.nativeElement, options);

    // hide logo and top buttons
    const logo: HTMLElement = this.componentElement.nativeElement.querySelector('.tui-image-editor-header-logo');
    logo.style.display = 'none';
    const topButtonsConatiner: HTMLElement = this.componentElement.nativeElement.querySelector('.tui-image-editor-header-buttons');
    topButtonsConatiner.innerHTML = '';
    // add cancel button
    const cancelButton: HTMLElement = document.createElement('button');
    cancelButton.innerText = 'Cancel';
    cancelButton.setAttribute('style', this.getThemeStyle('loadButton'));
    this.unlisteners.push(this.renderer.listen(cancelButton, 'click', () => this.dialogRef.close()));
    // add save button
    const saveButton: HTMLElement = document.createElement('button');
    saveButton.innerText = 'Save';
    saveButton.setAttribute('style', this.getThemeStyle('downloadButton'));
    this.unlisteners.push(this.renderer.listen(saveButton, 'click', () => this.onSave()));

    topButtonsConatiner.appendChild(cancelButton);
    topButtonsConatiner.appendChild(saveButton);
  }

  ngOnDestroy(): void {
    this.unlisteners.forEach(u => u());

    if (this.editorInstance) {
      this.editorInstance.destroy();
      this.editorInstance = null;
    }
  }

  onSave(): void {
    fetch(this.editorInstance.toDataURL()).then(
      res => res.blob().then(
        blob => this.dialogRef.close(blob)
      )
    );
  }

  private getThemeStyle(name: string): string {
    const keys = Object.keys(this.theme).filter(x => x.startsWith(name + '.'));
    return keys.map(k => `${k.replace(name + '.', '').replace(/([A-Z])/g, '-$1')}: ${this.theme[k]}`).join('; ');
  }

}

const whiteTheme = {
  // 'common.bi.image': 'https://uicdn.toast.com/toastui/img/tui-image-editor-bi.png',
  'common.bisize.width': '251px',
  'common.bisize.height': '21px',
  // 'common.backgroundImage': './img/bg.png',
  'common.backgroundColor': '#fff',
  'common.border': '1px solid #c1c1c1',

  // header
  'header.backgroundImage': 'none',
  'header.backgroundColor': 'transparent',
  'header.border': '0px',

  // load button
  'loadButton.backgroundColor': '#fff',
  'loadButton.border': '1px solid #ddd',
  'loadButton.color': '#222',
  'loadButton.fontFamily': '"Proxima Nova", "Helvetica Neue", sans-serif',
  'loadButton.fontSize': '14px',
  'loadButton.marginRight': '5px',

  // download button
  'downloadButton.backgroundColor': '#1d91ff',
  'downloadButton.border': '1px solid #1d91ff',
  'downloadButton.color': '#fff',
  'downloadButton.fontFamily': '"Proxima Nova", "Helvetica Neue", sans-serif',
  'downloadButton.fontSize': '14px',

  // main icons
  'menu.normalIcon.color': '#8a8a8a',
  'menu.activeIcon.color': '#555555',
  'menu.disabledIcon.color': '#434343',
  'menu.hoverIcon.color': '#e9e9e9',
  'menu.iconSize.width': '24px',
  'menu.iconSize.height': '24px',

  // submenu icons
  'submenu.normalIcon.color': '#8a8a8a',
  'submenu.activeIcon.color': '#555555',
  'submenu.iconSize.width': '32px',
  'submenu.iconSize.height': '32px',

  // submenu primary color
  'submenu.backgroundColor': 'transparent',
  'submenu.partition.color': '#e5e5e5',

  // submenu labels
  'submenu.normalLabel.color': '#858585',
  'submenu.normalLabel.fontWeight': 'normal',
  'submenu.activeLabel.color': '#000',
  'submenu.activeLabel.fontWeight': 'normal',

  // checkbox style
  'checkbox.border': '1px solid #ccc',
  'checkbox.backgroundColor': '#fff',

  // range style
  'range.pointer.color': '#333',
  'range.bar.color': '#ccc',
  'range.subbar.color': '#606060',

  'range.disabledPointer.color': '#d3d3d3',
  'range.disabledBar.color': 'rgba(85,85,85,0.06)',
  'range.disabledSubbar.color': 'rgba(51,51,51,0.2)',

  'range.value.color': '#000',
  'range.value.fontWeight': 'normal',
  'range.value.fontSize': '11px',
  'range.value.border': '0',
  'range.value.backgroundColor': '#f5f5f5',
  'range.title.color': '#000',
  'range.title.fontWeight': 'lighter',

  // colorpicker style
  'colorpicker.button.border': '0px',
  'colorpicker.title.color': '#000',
};
