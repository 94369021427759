<ngx-loading-bar [color]="'#ee2f65'" [includeSpinner]="false"></ngx-loading-bar>
<!-- account installation alert -->
<div class="dashboard-message" [class.dashboard-message-show]="dashboardWarning">
  <mat-icon class="warning-icon">warning_amber</mat-icon>
  <div> {{dashboardWarning?.description}} {{dashboardWarning?.instructions}} </div>
  <div style="width: 30px"></div>
  <button type="button" [routerLink]="dashboardWarning?.actionButtonRoute">
    {{dashboardWarning?.actionButtonText}}
  </button>
  <button type="button" (click)="dismissDashboardWarning()">dismiss</button>
</div>

<div class="app-container" [class.wide-menu]="language.langCode === 'ja'">

  <div class="app-header" [class.app-header-with-message]="dashboardWarning">

    <a style="display:block" routerLink="/cm">
      <span class="logo"></span>
    </a>

    <span class="fill-space"></span>

    <a *ngIf="isDemo" class="request-demo" href="https://trendemon.com/sign-up-for-a-demo/" target="_blank">
      Request Demo</a>
    <div *ngIf="!isAdminDashboard && !isDemo" class="account-name">{{selectedAccount?.name}}</div>
    <a *ngIf="isAdminDashboard && selectedAccount" class="account-name" routerLink="/cm">
      {{selectedAccount?.name}}</a>

    <mat-icon mat-button class="settings-button" routerLink="cm/settings" appPermission permission="account:editor">
      settings
    </mat-icon>

    <div class="user-menu-button" [matMenuTriggerFor]="menu" *ngIf="user">
      <img mat-button class="avatar" alt="{{user?.name}}" [src]="user.picture" />
      <mat-icon class="v-align-middle">arrow_drop_down</mat-icon>
    </div>
    <mat-icon class="settings-button" id="beamer-trigger">
      notifications_none
    </mat-icon>

    <mat-menu #menu="matMenu" xPosition="before" class="nested-menu-arrows-left">
      <div appPermission permission="system:admin">
        <button mat-menu-item (click)="showAdminDashboard()" translate>Admin Dashboard</button>
        <button mat-menu-item (click)="accountsSideMenu.toggle()" translate>Switch Accounts</button>
        <mat-divider></mat-divider>
      </div>

      <button mat-menu-item *ngIf="allowedAccounts" class="no-selected-outline" [matMenuTriggerFor]="accountsMenu"
        translate>Switch Accounts</button>
      <button mat-menu-item class="no-selected-outline" routerLink="/cm/settings" appPermission
        permission="account:editor" translate>Account Settings</button>
      <!-- Language Menu-->
      <button *ngIf="language" mat-menu-item [matMenuTriggerFor]="languageMenu" translate>Language</button>

      <a mat-menu-item class="no-selected-outline" href="http://support.trendemon.com/" target="_blank"
        translate>Support</a>
      <button mat-menu-item class="no-selected-outline" (click)="logout()" translate>Logout</button>
    </mat-menu>

    <mat-menu #languageMenu="matMenu" xPosition="before">
      <button mat-menu-item class="app-language-flag" *ngFor="let lang of languagesService.languages"
        (click)="setLanguage(lang.langCode)">
        <div class="app-flag app-flag-{{lang.flagCode}}"></div>
        {{lang.title}}
      </button>
    </mat-menu>

    <mat-menu #accountsMenu="matMenu" xPosition="before">
      <ng-container *ngIf="allowedAccounts">
        <button mat-menu-item class="app-language-flag" *ngFor="let acc of allowedAccounts"
          (click)="selectAccount(acc.id)">{{acc.name}}</button>
      </ng-container>
    </mat-menu>

  </div>

  <div class="top-menu" [class.top-menu-with-message]="dashboardWarning">
    <app-top-menu *ngIf="!isAdminDashboard" [modulesList]="modules"></app-top-menu>
    <div *ngIf="isAdminDashboard" class="top-title"><span>Admin Dashboard</span></div>
  </div>

  <mat-sidenav-container>
    <mat-sidenav #accountsSideMenu mode="over" position="end" style="padding-top: 70px">
      <app-account-select (accountSelected)="accountsSideMenu.close()"></app-account-select>
    </mat-sidenav>

    <mat-sidenav-content [class.sidenav-content-with-message]="dashboardWarning">
      <!-- sub menu -->
      <div class="sub-menu justify-center" *ngIf="activeMenu?.childs">
        <ng-container *ngFor="let child of activeMenu.childs">
          <a *ngIf="!isDisabledFeature(child.feature)" class="sub-menu-item" [routerLink]="child.route"
            routerLinkActive="active" translate>
            {{child.label}}
          </a>
          <a *ngIf="isDisabledFeature(child.feature)" class="sub-menu-item menu-link-disabled"
            [matTooltip]="'To enable, please contact our customer success' | translate" translate>
            {{child.label}}
          </a>
        </ng-container>
      </div>
      <div class="app-page">
        <div class="app-page-content" [class.app-page-content-no-sub-menu]="!(activeMenu?.childs)">
          <router-outlet></router-outlet>
        </div>
      </div>
    </mat-sidenav-content>

  </mat-sidenav-container>
</div>

<app-loader *ngIf="IsLoadingAuth"></app-loader>
