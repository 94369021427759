import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseConnectionStatus, LabelNamePair, IntegrationSettingType, IntegrationStatus, IntegrationState, LeadInformation, MaList, MaField, CrmCompaniesResponse } from '../../models/integrations';
import { environment as config } from '../../../../../environments/environment';
import { IntegrationCrmItem, IntegrationCrmItemsRequest, IntegrationCrmTotalItemsRequest, integrations, IntegrationType } from './integrations';
import { TokenInterceptorSkipAccountHeader } from 'src/app/modules/shared/constants/interceptors-headers';
import { RulesConfig } from 'src/app/modules/shared/models/models';


@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {

  constructor(
    private http: HttpClient
  ) { }

  isCrm(integrationType: IntegrationType): boolean {
    return integrationType === IntegrationType.salesforce;
  }

  isCrmSupported(activeIntegrations: IntegrationStatus[]): boolean {
    return integrations.filter(i => i.supportDynamicTargetList && activeIntegrations.map(x => x.integrationId).indexOf(i.id) > -1).length > 0;
  }

  getActiveIntegrations(accountId?: number): Observable<IntegrationStatus[]> {
    const headers = accountId
      ? new HttpHeaders().set('AccountId', accountId.toString()).set(TokenInterceptorSkipAccountHeader, '')
      : undefined;
    return this.http.get<IntegrationStatus[]>(`${config.adminBaseUrl}/api/integrations/active`, { headers });
  }

  getIntegrationConfig<T extends IntegrationSettingType>(integrationId: number): Observable<T> {
    return this.http.get<T>(`${config.adminBaseUrl}/api/integrations/${integrationId}/settings`);
  }

  changeStatus(integration: number, stat: IntegrationState): Observable<unknown> {
    return this.http.put(`${config.adminBaseUrl}/api/integrations/${integration}/change_status`,
      {
        status: stat
      }
    );
  }
  delete(integrationId: number) {
    return this.http.delete(`${config.adminBaseUrl}/api/integrations/${integrationId}`, {
      responseType: 'text'
    });
  }
  checkConnection<T extends IntegrationSettingType>(integrationId: number, params: T): Observable<ResponseConnectionStatus> {
    return this.http.post<ResponseConnectionStatus>(`${config.adminBaseUrl}/api/integrations/${integrationId}/check-connection`, params);
  }

  saveIntegrationSettings<T extends IntegrationSettingType>(integrationId: number, params: T): Observable<void> {
    return this.http.post<void>(`${config.adminBaseUrl}/api/integrations/${integrationId}/save`, params);
  }

  getIntegrationSalesorceFilelds(): Observable<{ data: LabelNamePair[] }> {
    return this.http.get<{ data: LabelNamePair[] }>(`${config.adminBaseUrl}/api/integrations/loadField`);
  }

  getIntegrationsFields(integration: IntegrationType): Observable<MaField[]> {
    return this.http.get<MaField[]>(`${config.adminBaseUrl}/api/integrations/${integration}/fields`);
  }

  getIntegrationsLists(integration: IntegrationType): Observable<MaList[]> {
    return this.http.get<MaList[]>(`${config.adminBaseUrl}/api/integrations/${integration}/lists`);
  }

  getLeadByEmail(email: string, integrationId: number, fields: string[]): Observable<LeadInformation> {
    const params = {
      email,
      provider: integrationId.toString(),
      SpecificFields: fields
    };
    return this.http.get<LeadInformation>(`${config.adminBaseUrl}/api/integrations/leadByEmail`, { params });
  }
  getMatchingGoals(email): Observable<number[]> {
    return this.http.get<number[]>(`${config.adminBaseUrl}/api/integrations/matchingGoals?email=${email}`);
  }
  getCrmCompaniesFields(integration: IntegrationType, crmObject: string): Observable<MaField[]> {
    return this.http.get<MaField[]>(`${config.adminBaseUrl}/api/integrations/${integration}/companies/crmfields/${crmObject}`);
  }

  getCrmCompanies(integration: IntegrationType, listConfig: RulesConfig, configObject: string, targetListId: number, limit: number): Observable<CrmCompaniesResponse> {
    return this.http.post<CrmCompaniesResponse>(`${config.adminBaseUrl}/api/integrations/${integration}/companies`,
      { targetListId, listConfig, configObject, provider: integration, limit }
    );
  }

  getCrmItemsFields(integration: IntegrationType, crmObject: string): Observable<MaField[]> {
    return this.http.get<MaField[]>(`${config.adminBaseUrl}/api/integrations/${integration}/crm_items/fields?objectName=${crmObject}`);
  }

  getCrmItems(integration: IntegrationType, request: IntegrationCrmItemsRequest): Observable<IntegrationCrmItem[]> {
    return this.http.post<IntegrationCrmItem[]>(`${config.adminBaseUrl}/api/integrations/${integration}/crm_items/items`, request);
  }

  getCrmTotalItems(integration: IntegrationType, request: IntegrationCrmTotalItemsRequest): Observable<number> {
    return this.http.post<number>(`${config.adminBaseUrl}/api/integrations/${integration}/crm_items/total`, request);
  }
}
