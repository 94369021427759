import { RulesConfig } from 'src/app/modules/shared/models/models';


export enum IntegrationCategory {
  MarketingAutomation,
  Crm,
  General,
}

export enum IntegrationType {
  mailchimp = 1,
  silverpop = 2,
  hubspot = 3,
  pardot = 4,
  custom = 5,
  soap = 6,
  autopilot = 7,
  marketo = 8,
  acton = 9,
  salesforce = 10,
  hubspotCrm = 11,
  microsoftDynamics = 12,
  slack = 13
}

export type IntegrationCrmItem = { [key: string]: string };

export interface IntegrationCrmTotalItemsRequest {
  targetListId: number;
  rulesObject: string;
  rules: RulesConfig;
  filterField: string;
  filterValue: string;
  filterValueType: IntegrationCrmFieldValueType;
}

export interface IntegrationCrmItemsRequest extends IntegrationCrmTotalItemsRequest {
  itemFields: string[];
  limit: number;
  offset: number;
}

export enum IntegrationCrmFieldValueType
{
    Text = 1,
    Number = 2,
}

export interface Integration {
  id: IntegrationType;
  category: IntegrationCategory;
  name: string;
  logo: string;
  enabled: boolean;
  comingSoon?: boolean;
  description?: string;
  route: string;
  guideUrl?: string;
  goalAvailable: boolean;
  label: string;
  experienceAvailable: boolean;
  sendLeadsAvailable: boolean;
  sendActivityAvailable: boolean;
  supportDynamicTargetList: boolean;
}
export const integrations: Integration[] = [
  {
    id: IntegrationType.hubspot,
    category: IntegrationCategory.MarketingAutomation,
    name: 'hubspot',
    label: 'HubSpot',
    logo: 'assets/integrations/hubspot-logo.png',
    route: '../hubspot',
    guideUrl: 'https://support.trendemon.com/hubspot-integration',
    enabled: true,
    description: 'Create goals and target campaigns based on Hubspot fields and lists.',
    goalAvailable: true,
    experienceAvailable: true,
    sendLeadsAvailable: true, sendActivityAvailable: true,
    supportDynamicTargetList: false
  },
  {
    id: IntegrationType.marketo,
    category: IntegrationCategory.MarketingAutomation,
    name: 'marketo',
    label: 'Marketo',
    logo: 'assets/integrations/marketo_logo.png',
    route: '../marketo',
    guideUrl: 'https://support.trendemon.com/marketo-integration',
    enabled: true,
    description: 'Create goals and target campaigns based on Marketo fields and lists.',
    goalAvailable: true,
    experienceAvailable: true,
    sendLeadsAvailable: true,
    sendActivityAvailable: false,
    supportDynamicTargetList: false
  },
  {
    id: IntegrationType.pardot,
    category: IntegrationCategory.MarketingAutomation,
    name: 'pardot',
    label: 'Pardot',
    logo: 'assets/integrations/pardot-logo.png',
    route: '../pardot',
    guideUrl: 'https://support.trendemon.com/pardort-integration',
    enabled: true,
    description: 'Create goals and target campaigns based on Pardot fields and lists.',
    goalAvailable: true,
    experienceAvailable: true,
    sendLeadsAvailable: true,
    sendActivityAvailable: false,
    supportDynamicTargetList: false
  },
  {
    id: IntegrationType.acton,
    category: IntegrationCategory.MarketingAutomation,
    name: 'acton',
    label: 'Acton',
    logo: 'assets/integrations/act-on_logo.png',
    route: '../acton',
    enabled: true,
    description: 'Create goals and target campaigns based on ActOn fields and lists.',
    goalAvailable: false,
    experienceAvailable: false,
    sendLeadsAvailable: false,
    sendActivityAvailable: false,
    supportDynamicTargetList: false
  },
  {
    id: IntegrationType.mailchimp,
    category: IntegrationCategory.MarketingAutomation,
    name: 'mailchimp',
    label: 'MailChimp',
    logo: 'assets/integrations/mailchimp-logo.png',
    route: '../mailchimp',
    enabled: true,
    description: 'Enables register visitor to newsletters',
    goalAvailable: false, experienceAvailable: false,
    sendLeadsAvailable: false, sendActivityAvailable: false,
    supportDynamicTargetList: false
  },
  {
    id: IntegrationType.salesforce,
    category: IntegrationCategory.Crm,
    name: 'salesforce',
    label: 'SalesForce',
    logo: 'assets/integrations/Salesforce_logo.png',
    route: '../salesforce',
    guideUrl: 'https://support.trendemon.com/salesforce-integration',
    enabled: true,
    description: 'Create audiences based on Salesforce opportunities and fields.',
    goalAvailable: true,
    experienceAvailable: false,
    sendLeadsAvailable: true,
    sendActivityAvailable: false,
    supportDynamicTargetList: true
  },
  {
    id: IntegrationType.hubspotCrm,
    category: IntegrationCategory.Crm,
    name: 'hubspot_crm',
    label: 'Hubspot CRM',
    logo: 'assets/integrations/hubspot-crm-logo.png',
    route: '',
    enabled: false,
    comingSoon: true,
    goalAvailable: false,
    experienceAvailable: false,
    sendLeadsAvailable: false,
    sendActivityAvailable: false,
    supportDynamicTargetList: false
  },
  {
    id: IntegrationType.microsoftDynamics,
    category: IntegrationCategory.Crm,
    name: 'microsoft_dynamics',
    label: 'Microsoft Dynamics 365',
    logo: 'assets/integrations/Microsoft-Dynamics-logo.png',
    route: '',
    enabled: false,
    comingSoon: true,
    goalAvailable: false,
    experienceAvailable: false,
    sendLeadsAvailable: false,
    sendActivityAvailable: false,
    supportDynamicTargetList: false
  },
  {
    id: IntegrationType.slack,
    category: IntegrationCategory.General,
    name: 'slack',
    label: 'Slack',
    logo: 'assets/integrations/slack.png',
    route: '../slack',
    enabled: true,
    description: 'Connect Slack to get real-time notifications and updates.',
    comingSoon: false,
    goalAvailable: false,
    experienceAvailable: false,
    sendLeadsAvailable: false,
    sendActivityAvailable: false,
    supportDynamicTargetList: false
  },
  /* disabled integrations
  { id: 2, name: 'silverpop', label: 'Silverpop', logo: 'assets/integrations/silverpop.png', route: '../silverpop', enabled: false, goalAvailable: false, experienceAvailable: false, sendLeadsAvailable: false, sendActivityAvailable: false },
  { id: 5, name: 'custom', label: 'Custom', logo: 'assets/integrations/custom.png', route: '../custom', enabled: false, goalAvailable: false, experienceAvailable: false, sendLeadsAvailable: false, sendActivityAvailable: false },
  { id: 6, name: 'soap', label: 'SOAP', logo: 'assets/integrations/soap.png', route: '../soap', enabled: false, goalAvailable: false, experienceAvailable: false, sendLeadsAvailable: false, sendActivityAvailable: false },
  { id: 7, name: 'autopilot', label: 'Autopilot', logo: 'assets/integrations/autopilot.png', route: '../autopilot', enabled: false, goalAvailable: false, experienceAvailable: false, sendLeadsAvailable: false, sendActivityAvailable: false },
  */
];

