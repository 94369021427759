import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import { UnitUiTypes, UnitVisibilities } from '../../account/experience/experience-configs';
import { Color, LineOptions, PointPrefixedHoverOptions, PointPrefixedOptions } from 'chart.js';
import { environment } from 'src/environments/environment';
import { UnitType, UnitUiTypeId } from '../../account/experience/models';
import { MaField } from '../../account/models/integrations';
import { comparisonInputType, ruleOperatorType } from '../models/models';
import { ModalsService } from '../services/modals.service';
import JSONBigInt from "json-bigint";
import { maDealStages } from '../constants/MaDealStages';

export class Utils {
  static deepClone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
  }

  static distictArray<T extends string | number>(array: T[]): T[] {
    return Array.from(new Set(array));
  }

  static trimStart(str: string, startStr: string): string {
    return str.startsWith(startStr) ? str.substring(startStr.length) : str;
  }

  static isAbsoluteUrl(url: string): boolean {
    return url.startsWith('http://') || url.startsWith('https://');
  }

  static cleanDomain(domain: string): string {
    let domainOnly = domain.toLowerCase();
    try {
      const domainUrl = new URL(domain);
      domainOnly = domainUrl.hostname;
    } catch { }

    for (const startStr of ['www.', 'www2.', 'www3.']) {
      domainOnly = Utils.trimStart(domainOnly, startStr);
    }

    return domainOnly;
  }

  static toSeconds(d: Date): number {
    return Math.round(d.getTime() / 1000);
  }

  static sleep(milliSec: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, milliSec));
  }

  static getDiffPercents(current: number, previous: number): number {
    if (current && !previous) {
      return 100;
    } else if (!current && !previous) {
      return 0;
    }
    const diff = current - previous;
    const diffRatio = diff / (previous || 1);
    const percents = Number((diffRatio * 100).toFixed(2));
    return percents;
  }

  static roundToTwoDecimal(val: string | number) {
    const num = Number(val) || 0;
    return Math.round((num + 0.00001) * 100) / 100;
  }

  static getOrdinalSuffix(n: number): string {
    let ord = 'th';

    if (n % 10 == 1 && n % 100 != 11)
    {
      ord = 'st';
    }
    else if (n % 10 == 2 && n % 100 != 12)
    {
      ord = 'nd';
    }
    else if (n % 10 == 3 && n % 100 != 13)
    {
      ord = 'rd';
    }

    return ord;
  }

  static calcCTR(impressions: number = 0, clicks: number = 0): number {
    return impressions ? parseFloat((clicks / impressions * 100).toFixed(2)) : 0;
  }

  static downloadFile(url: string, name?: string) {
    const dwldLink = document.createElement('a');
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    // if Safari open in new window to save file with random filename
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    if (name) {
      dwldLink.setAttribute('download', name);
    }
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  static getDays(seconds: number) {
    return Math.ceil(seconds / (24 * 3600)) || 1;
  }

  static buildQueryParams(source: Record<string, any>): HttpParams {
    let params: HttpParams = new HttpParams();

    Object.keys(source).forEach((key: string) => {
      const value: string | number | boolean | Array<number | string> = source[key];

      if ((typeof value !== 'undefined') && (value !== null)) {
        if (value instanceof Array) {
          // value is an array
          value.forEach((item) => {
            params = params.append(`${key.toString()}[]`, item.toString());
          });
        } else {
          // value is a string, number or boolean
          params = params.append(key, value.toString());
        }
      }
    });

    return params;
  }

  public static newGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      /* eslint-disable one-var */
      /* eslint-disable no-bitwise */
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public static findFormInvalidControls(formGroup: UntypedFormGroup) {
    const invalid = [];
    const controls = formGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  public static camelCaseToTitle(camelCaseStr: string): string {
    return camelCaseStr
      // insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, str => str.toUpperCase());
  }

  public static capitalizeFirst(string: string): string {
    return string[0].toUpperCase() + string.slice(1);
  }

  public static formatDateToText(date: string): string {
    if (!date) { return ''; }
    const formattedDate = formatDate(moment(date).valueOf(), 'MMM d, yyyy', 'en');
    return formattedDate;
  }

  public static formatDateTimeToText(date: string): string {
    if (!date) { return ''; }
    const formattedDate = formatDate(moment(date).valueOf(), 'MMM dd, yyyy HH:mm', 'en');
    return formattedDate;
  }

  public static formatDateToTextFromTimestamp(timestamp?: number): string {
    if (timestamp !== null) {
      return moment.unix(timestamp).format('MMM DD, YYYY');
    }
    return '';
  }

  public static formatDateTimeToTextFromTimestamp(timestamp?: number): string {
    if (timestamp !== null) {
      return moment.unix(timestamp).format('MMM DD, yyyy HH:mm');
    }
  }

  public static removeObjectEmptyProperties(o: Record<string, unknown>): Record<string, unknown> {
    return Object.entries(o).reduce((a, [k, v]) => (v == null ? a : (a[k] = v, a)), {});
  }

  public static groupObjectsByProperty(objectsArray: any[], key: string): any[] {
    return objectsArray.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [
          ...(result[item[key]] || []),
          item,
        ],
      }), {});
  }

  public static getUnitTypeLabel(type: string): string {
    const typeItem = UnitUiTypes.find(x => x.id ? UnitUiTypeId[Number(x.id)].toLowerCase() === type.toLowerCase() : false);
    return typeItem ? typeItem.name : type;
  }

  public static getUnitEditLink(type: string, unitType: UnitType): string {
    if (unitType === UnitType.Offer) {
      return '/cm/experience/journey/offers/edit/';
    } else if (unitType === UnitType.Campaign) {
      switch (type) {
        case 'hub': {
          return '/cm/experience/journey/hubs/edit/';
        }
        case 'script': {
          return '/cm/experience/journey/remarkets/edit/';
        }
        default: {
          return '/cm/experience/journey/campaigns/edit/';
        }
      }
    }
  }

  public static getUnitLayoutLabel(layout: string): string {
    const typeItem = UnitVisibilities.find(x => x.id === layout);
    return typeItem ? typeItem.name : '';
  }

  public static getLineChartOptions(lineColor: Color, fillColor: Color):
    Partial<LineOptions & PointPrefixedOptions & PointPrefixedHoverOptions> {
    return {
      backgroundColor: fillColor,
      borderColor: lineColor,
      pointBackgroundColor: lineColor,
      pointBorderColor: lineColor,
      pointHoverBackgroundColor: lineColor,
      pointHoverBorderColor: lineColor,
      fill: true,
      tension: 0.4,
    };
  }

  public static isDemo(): boolean {
    return window.location.hostname === 'demo-webapp.trendemon.com';
  }

  public static async fileFromUrl(url: string): Promise<File> {
    const imageUrl = new URL(url);
    const imageFileName = imageUrl.pathname.slice(imageUrl.pathname.lastIndexOf('/') + 1);
    const imagePath = Utils.externalLink(url);
    try {
      const fetchResponse = await fetch(imagePath);
      if (fetchResponse.ok) {
        const imageBlob = await (fetchResponse).blob();
        return new File([imageBlob], imageFileName);
      }
    } catch (ex) {
      console.log('image fetch failed', ex);
      return null;
    }
  }

  public static externalLink(url: string): string {
    return `${environment.fetchFileUrl}?url=${encodeURIComponent(url)}`;
  }

  public static fieldsOperatorsMapping(field: MaField, forQuery = false): { fieldType: comparisonInputType; operatorType: ruleOperatorType } {
    let fieldType: comparisonInputType;
    let operatorType: ruleOperatorType;
    switch (field.fieldType) {
      case 'integer':
      case 'float':
      case 'currency':
      case 'number':
      case 'xsd:int':
      case 'xsd:double': {
        fieldType = comparisonInputType.number;
        operatorType = ruleOperatorType.ruleIntOperators;
        break;
      }
      case 'date':
      case 'datetime':
      case 'xsd:date':
      case 'xsd:dateTime': {
        fieldType = comparisonInputType.dateTime;
        operatorType = ruleOperatorType.ruleIntOperators;
        break;
      }
      case 'xsd:boolean':
      case 'boolean': {
        fieldType = comparisonInputType.hidden;
        operatorType = ruleOperatorType.ruleBooleanOperators;
        break;
      }
      default: {
        fieldType = comparisonInputType.textField;
        operatorType = forQuery ? ruleOperatorType.ruleStringOperatorsForQuery : ruleOperatorType.ruleStringOperatorsFull;
      }
    }
    return { fieldType, operatorType };
  }

  public static async isAdBlocker(): Promise<boolean> {
    // test fetch header request to script
    const test = new Request(
      'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
      { method: 'HEAD', mode: 'no-cors' }
    );

    // fire the request
    return Promise.resolve(fetch(test)).then((res) => false).catch((err) => true);
  }

  public static async GetUrlHeaders(url: string): Promise<{ [key: string]: string }> {
    const headersUrl = `${environment.getHeadersUrl}?url=${encodeURIComponent(url)}`;
    try {
      return (await fetch(headersUrl)).json();
    } catch {
      return {};
    }
  }

  public static async handleError(ex: any, modals: ModalsService): Promise<void> {
    if (ex instanceof HttpErrorResponse) {
      await modals.Alert('Failed: ' + ex.statusText);
    } else if (ex instanceof Error) {
      await modals.Alert('Failed: ' + ex.message);
    } else if (typeof ex === 'string') {
      await modals.Alert('Failed: ' + ex);
    }
  }

  public static noCorsHtml(url: string, contentUrl: string = null): string {
    return `${contentUrl || environment.contentUrl}/serve/html?url=${encodeURIComponent(url)}`;
  }

  public static async isIframeBlocked(url: string): Promise<boolean> {
    const headers = await Utils.GetUrlHeaders(url);
    return this.isIframeBlockedByXFrame(url, headers) || this.isIframeBlockedByCsp(headers);
  }

  public static ParseJsonBigIntAsString<T>(json: string): T {
    return JSONBigInt({ storeAsString: true }).parse(json) as T;
  }

  public static maDealStageName(stageId: string): string {
    const stage = maDealStages.find(stage => stage.id === stageId);
    return stage ? stage.name : stageId;
  }

  private static isIframeBlockedByXFrame(url: string, headers: { [key: string]: string }): boolean {
    const frameOptionEntry = Object.entries(headers).find(h => h[0].toLowerCase() === 'x-frame-options');
    if (!frameOptionEntry) { return false; }
    const frameOption = frameOptionEntry[1].toLowerCase();
    const urlParts = new URL(url);
    return frameOption === 'deny' || (frameOption === 'sameorigin' && !(window.location.hostname.includes(urlParts.hostname)));
  }

  private static isIframeBlockedByCsp(headers: { [key: string]: string }): boolean {
    const cspEntry = Object.entries(headers).find(h => h[0].toLowerCase() === 'content-security-policy');
    if (!cspEntry) { return false; }
    return cspEntry[1].toLowerCase().includes('frame-ancestors');
  }

}
