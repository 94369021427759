import { Injectable, DEFAULT_CURRENCY_CODE, Inject } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { Router, NavigationStart, ActivatedRouteSnapshot } from '@angular/router';
import { AccountsService } from './accounts.service';
import { AuthService } from 'src/app/auth/auth.service';
import { filter, first } from 'rxjs/operators';
import { IAccount, AccountCurrency, AccountFeature } from '../models/IAccount';
import * as _ from 'lodash-es';

class AccountContext {
  accountId: number = null;
  recentlyUsedAccountIds: number[] = [];
}

@Injectable({
  providedIn: 'root'
})
export class AccountContextService {
  private readonly STORAGE_KEY = 'ctx';
  private context: AccountContext;
  private recentAccounts: IAccount[] = [];
  initialized$ = new BehaviorSubject<boolean>(false);
  recentAccounts$ = new BehaviorSubject<IAccount[]>([]);
  selectedAccount$ = new BehaviorSubject<IAccount>(null);
  url: string = null;

  constructor(
    private router: Router,
    private authService: AuthService,
    private accountsService: AccountsService,
    @Inject(DEFAULT_CURRENCY_CODE) private defaultCurrency) {

    router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        this.url = e.url;
      });

    this.init();
  }

  get accountId(): number {
    return this.selectedAccount$.value?.id;
  }
  get account(): IAccount | null {
    return this.selectedAccount$.value;
  }
  get accountCurrency(): AccountCurrency {
    return this.selectedAccount$?.value.currency || this.defaultCurrency;
  }

  async setDefaultContext(): Promise<void> {
    if (await this.authService.isSysAdmin()) {
      // admin user
      return;
    }

    // account user
    const user = this.authService.profile.value;
    if (user.accountIds.length > 0) {
      // set first account
      this.context.accountId = user.accountIds[0];
      await this.initAccount();
    } else {
      // account user has no authorized account - logout
      this.authService.logout();
    }
  }

  async routeToDefaultByRole(): Promise<void> {
    if (await this.authService.isSysAdmin()) {
      // admin user - route to admin dashboard
      this.router.navigate(['admin']);
    } else {
      // account user
      const user = this.authService.profile.value;
      if (user.accountIds.length > 0) {
        // route to first account
        this.context.accountId = user.accountIds[0];
        await this.initAccount(true);
      } else {
        // account user has no authorized account - logout
        this.authService.logout();
      }
    }
  }

  async isAuthorizedAccount(accountId: number) {
    const isSysAdmin = await this.authService.isSysAdmin();
    if (isSysAdmin) {
      // sys admin - all accounts are allowed
      return true;
    }

    const user = this.authService.profile.value;
    if (user.accountIds.includes(accountId)) {
      // selected account is authorized for the logged in user
      return true;
    }

    return false;
  }

  async setAccount(account: IAccount, forceRoute: boolean = false): Promise<void> {
    if (account) {
      let isSendLogin = false;
      if (this.authService.isAuthCallbackHandled(true) || this.context?.accountId !== account.id) {
        isSendLogin = true;
      }

      document.dispatchEvent(new CustomEvent('accountChanged', {
        detail: { account }
      }));

      // set selection & latest accounts
      this.context.accountId = account.id;
      _.remove(this.recentAccounts, la => la.id === account.id);
      _.remove(this.context.recentlyUsedAccountIds, id => id === account.id);

      this.recentAccounts.unshift(account);
      this.context.recentlyUsedAccountIds.unshift(account.id);

      this.recentAccounts$.next(this.recentAccounts);

      // save selections in session storage
      this.persistContext();

      // publish selectd account
      this.selectedAccount$.next(account);
      const url2Navigate = localStorage.getItem('postAccountSelectionUrl');
      if (url2Navigate) {
        localStorage.removeItem('postAccountSelectionUrl');
        this.router.navigateByUrl(url2Navigate);
      } else if (forceRoute) {
        this.router.navigate(['cm']);
      }

      if (isSendLogin) {
        await lastValueFrom(this.accountsService.addAccountLogin());
      }
    } else {
      this.context.accountId = null;
      this.persistContext();
      this.selectedAccount$.next(null);
    }
  }

  async reloadAccount(): Promise<void> {
    if (this.accountId) {
      const account = await lastValueFrom(this.accountsService.getAccountFeatures(this.accountId, true));
      this.selectedAccount$.next(account);
    }
  }

  async changeAccount(accountId: number, routeSnapshot: ActivatedRouteSnapshot): Promise<IAccount> {
    const prevAccount = this.accountId;
    const accountFeatures = await lastValueFrom(this.accountsService.getAccountFeatures(accountId));

    if (!this.url.startsWith('/cm') || this.isRouteWithParams(routeSnapshot)) {
      await this.router.navigate(['cm']);
    }
    this.setAccount(accountFeatures);
    if (prevAccount && prevAccount !== accountId) {
      sessionStorage.removeItem('warning_dismissed');
      setTimeout(() => window.location.reload(), 500);
    }

    return accountFeatures;
  }

  private async init() {
    // wait for user to be authenticated
    await lastValueFrom(this.authService.isAuthenticated.pipe(first(x => x)));

    this.context = this.getPersistedContext();
    this.persistContext();

    // init latest accounts
    if (this.context.recentlyUsedAccountIds.length > 0) {
      await this.initLatestAccountsIds();
    }
    this.recentAccounts$.next(this.recentAccounts);

    // init according to selections values
    await this.initAccount();

    this.initialized$.next(true);
  }

  private async initLatestAccountsIds(): Promise<void> {
    if (await this.authService.isSysAdmin()) {
      // admin user - keep the latest account found in the session storage
      return;
    }

    // get user
    const user = this.authService.profile.value;
    if (!user) {
      // no user found - remove all latest account
      this.context.recentlyUsedAccountIds = [];
      this.persistContext();
      return;
    }

    // make sure all latest accounts are authorized for user
    this.context.recentlyUsedAccountIds = this.context.recentlyUsedAccountIds.filter(x => user.accountIds.includes(x));
    this.persistContext();
  }

  private async initAccount(forceRoute: boolean = false): Promise<void> {
    if (this.context.accountId) {
      if (await this.isAuthorizedAccount(this.context.accountId)) {
        // selected account is authorized for user
        const account = await lastValueFrom(this.accountsService.getAccountFeatures(this.context.accountId));
        this.setAccount(account, forceRoute);
      } else {
        // selected account not authorized for user
        await this.routeToDefaultByRole();
      }
    }
  }

  private getPersistedContext(): AccountContext {
    // init selections from session storage
    const serialized = sessionStorage.getItem(this.STORAGE_KEY);
    if (serialized) {
      return JSON.parse(serialized) as AccountContext;
    }
    return new AccountContext();
  }

  private persistContext(): void {
    sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(this.context));
  }

  private isRouteWithParams(routeSnapshot: ActivatedRouteSnapshot): boolean {
    if (routeSnapshot.children.length === 0) {
      return false;
    }

    for (const child of routeSnapshot.children) {
      if (Object.keys(child.params).length > 0 || this.isRouteWithParams(child)) {
        return true;
      }
    }
    return false;
  }

  /**
   * returns true if {acc} supports feature {feature}, false otherwise
   *
   * @param  acc the account
   * @param  feature the feature name
   * @returns boolean
   */
  supportsFeature(acc: IAccount, feature: AccountFeature): boolean {
    if (acc) {
      return acc.features.includes(feature);
    }
    return false;
  }

  supports(feature: AccountFeature): boolean {
    return this.supportsFeature(this.selectedAccount$.value, feature);
  }

  async waitForInit() {
    await lastValueFrom(this.initialized$.pipe(first(x => x)));
  }
}
