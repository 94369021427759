import { Directive, Renderer2, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Permission } from '../auth/auth0-user';
import { Subscription } from 'rxjs';

/*
  <div appPermission permission="PERMISSION-NAME" action="disable">
  <div appPermission permission="FEATURE-NAME">
*/
@Directive({
  selector: '[appPermission]'
})
export class PermissionDirective implements OnInit, OnDestroy {

  @Input() permission: Permission;
  @Input() action: 'hide' | 'disable' | 'invisible' = 'hide';
  sub: Subscription;

  constructor(private renderer: Renderer2, private elementRef: ElementRef, private userService: AuthService) {
  }
  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  async ngOnInit() {
    if (this.permission) {
      this.sub = this.userService.profile.subscribe((profile) => {
        const permitted = this.userService.isPermitted(profile, this.permission);
        if (!permitted) {
          if (!this.action || this.action === 'hide') {
            this.elementRef.nativeElement.style.display = 'none';
          } else if (this.action === 'invisible') {
            this.elementRef.nativeElement.style.visibility = 'hidden';
          } else {
            this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', 'disabled');
            this.renderer.addClass(this.elementRef.nativeElement, 'disabled-non-permitted-module');
          }
        } else {
          if (!this.action || this.action === 'hide') {
            this.elementRef.nativeElement.style.display = null;
          } else if (this.action === 'invisible') {
            this.elementRef.nativeElement.style.visibility = 'visible';
          } else {
            this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
            this.renderer.removeClass(this.elementRef.nativeElement, 'disabled-non-permitted-module');
          }
        }
      });

    }
  }
}

