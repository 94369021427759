export const environment = {
  production: true,
  environment: 'prod',
  adminBaseUrl: 'https://adminapi.trendemon.com',
  insightsBaseUrl: 'https://prod-insights.trendemon.com',
  engagmentPreviewBaseUrl: 'https://assets.trendemon.com',
  accountBaseUrl: 'https://prod-insights.trendemon.com',
  dictionariesBaseUrl: 'https://prod-insights.trendemon.com',
  mediaBaseUrl: 'https://pic.trendemon.com/autotask_logo',
  mediaUrl: 'https://pic.trendemon.com',
  trackingBaseUrl: 'https://trackingapi.trendemon.com',
  abmBaseUrl: 'https://abmservice.trendemon.com',
  fetchFileUrl: 'https://content.trendemon.com/prod/serve',
  getHeadersUrl: 'https://content.trendemon.com/prod/headers',
  contentUrl: 'https://content.trendemon.com',
  heapAppId: '4239188091',
  sentryEnv: 'production',
  sentryRelease: 'dashboard-prod-6734',
  // auth0
  auth0: {
    localToken: undefined,
    clientId: 'w7DcnOyMEtdOGVNBIVMcTd4pg7ek598I',
    domain: 'login.trendemon.com',
    audience: 'https://trendemon-prod.auth0.com/api/v2/',
    mockUser: undefined
  }
};
