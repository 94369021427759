<mat-toolbar [class.wide-buttons]="isWideButtons">
  <span *ngFor="let item of modulesList" class="top-menu-item">
    <!-- Handle branch node buttons here -->
    <span *ngIf="item.childs && item.childs.length > 0" [matTooltipDisabled]="!isDisabledFeature(item.feature)"
      [matTooltip]="'To enable, please contact our customer success' | translate">
      <button #menuTrigger="matMenuTrigger" mat-button [matMenuTriggerFor]="isAccountInitialized ? menu : null"
        class="top-menu-button" [appActiveMenuItem]="item" (mousedown)="clientClicked()" style="z-index:1050"
        [disabled]="item.disabled" [action]="item.actionFeatureOff" appPermission [permission]="item.permission"
        [disabled]="isDisabledMenu(item.feature)">
        <mat-icon *ngIf="item.iconName">{{item.iconName}}</mat-icon>
        <img *ngIf="item.iconImage" src="{{item.iconImage}}" />
        <span class="menu-label" translate>{{item.label}}</span>
      </button>
      <mat-menu #menu="matMenu" [overlapTrigger]="false" [hasBackdrop]="false" class="top-menu-dropdown">
        <span *ngFor="let child of item.childs">
          <!-- Handle leaf node menu items -->
          <span [matTooltipDisabled]="!isDisabledFeature(child.feature)"
            [matTooltip]="'To enable, please contact our customer success' | translate">
            <button mat-menu-item [routerLink]="child.route" class="btn-child-menu" routerLinkActive="active"
              appPermission [permission]="child.permission" [action]="child.actionFeatureOff"
              [disabled]="isDisabledFeature(child.feature)">
              <div class="child-menu">
                <div class="child-menu-icon"
                  [style.visibility]="child.iconName || child.iconImage ? 'visible' : 'hidden'">
                  <mat-icon *ngIf="child.iconName"
                    [fontSet]="child.iconOutline ? 'material-icons-outlined' : 'material-icons'">
                    {{child.iconName}}</mat-icon>
                  <img *ngIf="child.iconImage" src="{{child.iconImage}}" />
                </div>
                <div class="child-menu-text">
                  <div class="child-menu-label" color="accent" translate>{{child.label}}</div>
                  <div class="child-menu-desc" translate>{{child.description}}</div>
                </div>
              </div>
            </button>
          </span>
        </span>
      </mat-menu>
    </span>
    <!-- Leaf node buttons here -->
    <span *ngIf="!item.childs || item.childs.length === 0" [matTooltipDisabled]="!isDisabledFeature(item.feature)"
      [matTooltip]="'To enable, please contact our customer success' | translate">
      <button mat-button class="top-menu-button" [routerLink]="item.route" routerLinkActive="active" appPermission
        [permission]="item.permission" [action]="item.actionFeatureOff" [disabled]="isDisabledMenu(item.feature)">
        <mat-icon *ngIf="item.iconName">{{item.iconName}}</mat-icon>
        <img *ngIf="item.iconImage" src="{{item.iconImage}}" />
        <span class="menu-label" translate>{{item.label}}</span>
      </button>
    </span>
  </span>
</mat-toolbar>