import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthCallbackComponent } from './auth/auth-callback.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modules/shared/submodule-material.module';
import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory, SharedModule } from './modules/shared/shared.module';
import { AdminSideMenuComponent } from './components/admin-side-menu/admin-side-menu.component';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { AccountSelectComponent } from './components/account-select/account-select.component';
import { ActiveMenuItemDirective } from './components/active-menu-item.directive';
import { Router } from '@angular/router';


@NgModule({
  declarations: [
    AppComponent,
    AuthCallbackComponent,
    AdminSideMenuComponent,
    TopMenuComponent,
    AccountSelectComponent,
    ActiveMenuItemDirective,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LoadingBarHttpClientModule,
    MaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
      useValue: undefined
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [TopMenuComponent]
})
export class AppModule { }
