import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IAlertDialogData {
  title: string;
  message: string;
  okButtonText: string;
  okButtonColor: string;
}

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent {

  okButtonText: string;
  okButtonColor: string;

  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAlertDialogData) {

    this.okButtonText = data.okButtonText || 'OK';
    this.okButtonColor = data.okButtonColor || 'primary';
  }
}
