import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { AccountContextService } from '../modules/shared/services/account-context.service';
import { ModalsService } from '../modules/shared/services/modals.service';

@Component({
  selector: 'app-auth-callback',
  template: ``,
  styleUrls: []
})
export class AuthCallbackComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private selectionService: AccountContextService,
    private router: Router,
    private modals: ModalsService) { }

  async ngOnInit() {
    const callbackResponse = await this.authService.handleRedirectCallback();

    if (callbackResponse.error) {
      const isLogout = await this.modals.Confirm(
        `${callbackResponse.error.error}${callbackResponse.error.error_description ? `:${callbackResponse.error.error_description}` : ''}`,
        'Failed to login', 'Logout', 'primary', 'Close');
      if (isLogout) {
        await this.authService.logout();
      } else {
        this.router.navigateByUrl('/');
      }
      return;
    }

    // init authenticated user
    await this.authService.getIsAuthenticated(true);

    let targetRoute = callbackResponse.target;
    if (targetRoute === '' || targetRoute === '/') {
      targetRoute = await this.authService.userDefaultRoute();
    } else {
      await this.selectionService.setDefaultContext();
    }
    this.router.navigateByUrl(targetRoute);
  }
}
