import { NgModule, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MaterialModule } from './submodule-material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LottieModule } from 'ngx-lottie';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { PaginatorIntlService } from './services/PaginatorIntlService';
import { GaugeComponent } from './components/gauge/gauge.component';
import { DropDownComponent } from './components/drop-down/drop-down.component';
import { TotalStatisticsComponent } from './components/total-statistics/total-statistics.component';
import { LabelTooltipComponent } from './components/label-tooltip.component';
import { CellSpinnerComponent } from './components/cell-spinner.component';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { TruncatePipe } from './pipes/truncate.pipe';
import { FilterByPipe } from './pipes/filterBy.pipe';
import { DetailTableRowDirective } from '../shared/directives/detail-table-row.directive';
import { JourneyOverviewComponent } from './components/journey-overview/journey-overview.component';
import { DateRangeFilterComponent } from './components/filters/date-range-filter/date-range-filter.component';
import { TextFilterComponent } from './components/filters/text-filter/text-filter.component';
import { DropDownFilterComponent } from './components/filters/drop-down-filter/drop-down-filter.component';
import { TablePaginatorComponent } from './components/filters/table-paginator/table-paginator.component';
import { CsvExportService } from './services/csv-export.service';
import { UserNotificationService } from './user-notification.service';
import { AccountEntitiesService } from './services/account-entities.service';
import { AccountsService } from './services/accounts.service';
import { CacheInterceptor } from './cache.interceptor';
import { HttpErrorInterceptor } from './error.interceptor';
import { TokenInterceptor } from 'src/app/auth/token-interceptor';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RuleEngineGeneratorComponent } from './components/rule-engine-generator/rule-engine-generator.component';
import { CodeDisplayComponent } from './components/code-display.component';
import { InfoTooltipComponent } from './components/info-tooltip.component';
import { ValueEditComponent } from './components/value-edit/value-edit.component';
import { EmailMaskByPipe } from './pipes/email-mask.pipe';
import { DeleteConfirmDialogComponent } from './components/delete-confirm-dialog/delete-confirm-dialog.component';
import { PermissionDirective } from 'src/app/components/permission.directive';
import { SourceTypeComponent } from './components/source-type.component';
import { FeatureDirective } from 'src/app/components/feature.directive';
import { SafePipe } from './pipes/safe.pipe';
import { MultiselectAutocompleteComponent } from './components/multiselect-autocomplete/multiselect-autocomplete.component';
import { ImgUploaderComponent } from './components/img-uploader/img-uploader.component';
import { SpinnerColorDirective } from './directives/spinner-color.directive';
import { AutocompleteFilterComponent } from './components/filters/autocomplete-filter/autocomplete-filter.component';
import { NotInListValidatorDirective } from './validators/not-in-list-validator';
import { LoaderComponent } from './components/loader.component';
import { TinyLoaderComponent } from './components/tiny-loader.component';
import { LoadingDialogComponent } from './components/loading-dialog/loading-dialog.component';
import { ImageEditorDialogComponent } from './components/image-editor-dialog/image-editor-dialog.component';
import { ImageResizeComponent } from './components/image-resize/image-resize.component';
import { EditStringsListComponent } from './components/edit-strings-list/edit-strings-list.component';
import { IntegrationFieldsValuesEditComponent } from './components/integration-fields-values-edit/integration-fields-values-edit.component';
import { SelectGoalTypeComponent } from './components/select-goal-type/select-goal-type.component';
import { SelectUnitTypeComponent } from './components/select-unit-type/select-unit-type.component';
import { IFrameResizerDirective } from './directives/iframe-resizer.directive';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { RuleEnginePredicateComponent } from './components/rule-engine-generator/rule-engine-predicate/rule-engine-predicate.component';
import { UrlOrMailtoValidatorDirective } from './validators/url-or-mailto-validator';
import { DigitsValidatorDirective } from './validators/digits-validator';
import { DigitsOnlyDirective } from './directives/digits-only.directive';
import { TargetListTypePipe } from './pipes/targetList-type.pipe';
import { MultiFreeTextAutocompleteComponent } from './components/multi-freetext-autocomplete/multi-freetext-autocomplete.component';
import { FunnelGraphComponent } from './components/funnel-graph/funnel-graph.component';
import { UrlValidatorDirective } from './validators/url.validator';
import { ImageViewerComponent } from './components/image-viewer.component';
import { UnitPreviewComponent } from '../account/experience/unit-preview/unit-preview.component';
import { UnitCreativeCustomizeComponent } from '../account/experience/unit-creative-customize/unit-creative-customize.component';
import { UnitThemeComponent } from '../account/experience/unit-theme/unit-theme.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { UnitNameComponent } from '../account/experience/unit-name/unit-name.component';
import { PreStyleThumbnailComponent } from './components/pre-style-thumbnail/pre-style-thumbnail.component';
import { ExperienceSurveyEditComponent } from '../account/experience/experience-survey-edit/experience-survey-edit.component';
import { ExperienceFormFieldsComponent } from '../account/experience/experience-form-fields/experience-form-fields.component';
import { TargetListBuildTypePipe } from './pipes/targetListBuild-type.pipe';
import { RouterModule } from '@angular/router';
import { CssSelectorValidatorDirective } from './validators/css-selector.validator';
import { ColorPickerInputComponent } from '../standalone/components/color-picker-input/color-picker-input.component';
import { SignedDigitsValidatorDirective } from './validators/signed-digits-validator';
import { VideoValidatorDirective } from './validators/supported-video-script.validator';
import { ShowContentDialogComponent } from './components/show-content-dialog/show-content-dialog.component';

// For Lottie player - we need a separate function as it's required by the AOT compiler.
// Using import loads the lottie-web library on demand only when your animation gets rendered for the first time.
const playerFactory = (): any => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');

// required for AOT compilation
export const HttpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    DragDropModule,
    MatPaginatorModule,
    ColorPickerInputComponent,
    TinyLoaderComponent,
    CKEditorModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LottieModule.forRoot({ player: playerFactory })
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    TranslateModule,
    ConfirmationDialogComponent,
    AlertDialogComponent,
    GaugeComponent,
    DropDownComponent,
    TotalStatisticsComponent,
    LabelTooltipComponent,
    CellSpinnerComponent,
    LoadingBarHttpClientModule,
    TruncatePipe,
    FilterByPipe,
    SafePipe,
    EmailMaskByPipe,
    DetailTableRowDirective,
    JourneyOverviewComponent,
    DateRangeFilterComponent,
    TextFilterComponent,
    DropDownFilterComponent,
    TablePaginatorComponent,
    HttpClientModule,
    DragDropModule,
    CodeDisplayComponent,
    InfoTooltipComponent,
    ValueEditComponent,
    RuleEngineGeneratorComponent,
    DeleteConfirmDialogComponent,
    SourceTypeComponent,
    PermissionDirective,
    FeatureDirective,
    MultiselectAutocompleteComponent,
    ImgUploaderComponent,
    AutocompleteFilterComponent,
    NotInListValidatorDirective,
    LoaderComponent,
    LoadingDialogComponent,
    ImageEditorDialogComponent,
    ImageResizeComponent,
    EditStringsListComponent,
    IntegrationFieldsValuesEditComponent,
    SelectGoalTypeComponent,
    SelectUnitTypeComponent,
    IFrameResizerDirective,
    AutocompleteComponent,
    UrlOrMailtoValidatorDirective,
    UrlValidatorDirective,
    DigitsValidatorDirective,
    DigitsOnlyDirective,
    TargetListTypePipe,
    TargetListBuildTypePipe,
    MultiFreeTextAutocompleteComponent,
    FunnelGraphComponent,
    ImageViewerComponent,
    UnitPreviewComponent,
    UnitCreativeCustomizeComponent,
    UnitThemeComponent,
    UnitNameComponent,
    PreStyleThumbnailComponent,
    ExperienceSurveyEditComponent,
    ExperienceFormFieldsComponent,
    CssSelectorValidatorDirective,
    SignedDigitsValidatorDirective,
    VideoValidatorDirective,
    TinyLoaderComponent,
    ShowContentDialogComponent,
  ],
  declarations: [
    ConfirmationDialogComponent,
    AlertDialogComponent,
    GaugeComponent,
    DropDownComponent,
    TotalStatisticsComponent,
    LabelTooltipComponent,
    CellSpinnerComponent,
    TruncatePipe,
    FilterByPipe,
    SafePipe,
    EmailMaskByPipe,
    DetailTableRowDirective,
    JourneyOverviewComponent,
    DateRangeFilterComponent,
    TextFilterComponent,
    DropDownFilterComponent,
    TablePaginatorComponent,
    CodeDisplayComponent,
    InfoTooltipComponent,
    ValueEditComponent,
    RuleEngineGeneratorComponent,
    DeleteConfirmDialogComponent,
    SourceTypeComponent,
    PermissionDirective,
    FeatureDirective,
    MultiselectAutocompleteComponent,
    ImgUploaderComponent,
    SpinnerColorDirective,
    AutocompleteFilterComponent,
    NotInListValidatorDirective,
    LoaderComponent,
    LoadingDialogComponent,
    ImageEditorDialogComponent,
    ImageResizeComponent,
    EditStringsListComponent,
    IntegrationFieldsValuesEditComponent,
    SelectGoalTypeComponent,
    SelectUnitTypeComponent,
    IFrameResizerDirective,
    AutocompleteComponent,
    RuleEnginePredicateComponent,
    UrlOrMailtoValidatorDirective,
    UrlValidatorDirective,
    DigitsValidatorDirective,
    DigitsOnlyDirective,
    TargetListTypePipe,
    TargetListBuildTypePipe,
    MultiFreeTextAutocompleteComponent,
    FunnelGraphComponent,
    ImageViewerComponent,
    UnitPreviewComponent,
    UnitCreativeCustomizeComponent,
    UnitThemeComponent,
    UnitNameComponent,
    PreStyleThumbnailComponent,
    ExperienceSurveyEditComponent,
    ExperienceFormFieldsComponent,
    CssSelectorValidatorDirective,
    SignedDigitsValidatorDirective,
    VideoValidatorDirective,
    ShowContentDialogComponent,
  ],
  providers: [
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'USD' },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    AccountEntitiesService,
    AccountsService,
    CsvExportService,
    UserNotificationService,
    {
      provide: MatPaginatorIntl,
      useFactory: (translate) => {
        const service = new PaginatorIntlService();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService]
    }
  ]
})
export class SharedModule { }
