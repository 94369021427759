import { Injectable } from '@angular/core';
import { SettingsAccountDetails, SystemStatus } from '../../account/settings/models/settings-models';
import { SettingsService } from '../../account/settings/account-settings.service';
import { AccountContextService } from './account-context.service';
import { lastValueFrom } from 'rxjs';
import { IntegrationsService } from '../../account/settings/integrations/integrations.service';
import { IntegrationState, IntegrationStatus } from '../../account/models/integrations';
import { AccountWarning, AccountWarningType, AccountWarningsConfig } from '../models/account-warning.models';

@Injectable({
  providedIn: 'root'
})
export class AccountWarningsService {
  private _isScriptLive: boolean = null;
  private settings: SettingsAccountDetails;
  private integrationStatus: IntegrationStatus[];
  private firstIntegrationError: IntegrationStatus;

  constructor(
    private accountContext: AccountContextService,
    private settingsService: SettingsService,
    private integrationsService: IntegrationsService
  ) {
    this.accountContext.selectedAccount$.subscribe(async selectedAccount => {
      if (selectedAccount) {
        [this._isScriptLive, this.settings, this.integrationStatus] = await Promise.all([
          lastValueFrom(this.settingsService.getAccountIsScriptLive()),
          lastValueFrom(this.settingsService.getAccountDetails()),
          lastValueFrom(this.integrationsService.getActiveIntegrations())
        ]);
        this.firstIntegrationError = this.integrationStatus?.find(s => s.connectionStatus?.code !== 0);
      }
    });
  }

  get isScriptLive(): boolean { return this._isScriptLive; }
  get systemStatus(): SystemStatus { return this.settings?.systemStatus; }
  set systemStatus(value: SystemStatus) { this.settings.systemStatus = value; }
  get dashboardWarning(): Readonly<AccountWarning> {
    if (this.isScriptLive === false) {
      return this.getWarningByType(AccountWarningType.NoScript);
    } else if (this.systemStatus === 'DEMO') {
      return this.getWarningByType(AccountWarningType.NotLive);
    } else if (this.firstIntegrationError) {
      return this.getIntegrationWarning();
    } else {
      return null;
    }
  }
  get isPersonalizationAvailable(): boolean {
    return [AccountWarningType.NoScript, AccountWarningType.NotLive]
      .includes(this.dashboardWarning?.warningType);
  }

  private getWarningByType(warningType: AccountWarningType): Readonly<AccountWarning> {
    return AccountWarningsConfig.find(w => w.warningType === warningType);
  }

  private getIntegrationWarning(): Readonly<AccountWarning> {
    if (this.firstIntegrationError) {
      const rawWarning = this.getWarningByType(AccountWarningType.IntegrationError);
      return Object.freeze({
        ...rawWarning,
        description: rawWarning.description.replace('{{integration}}', this.firstIntegrationError.integrationName)
      })
    } else {
      return null;
    }
  }
}
