import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { IAuthUser } from './modules/shared/models/IAuthUser';
import { AccountContextService } from './modules/shared/services/account-context.service';
import { LanguagesService, ILanguage } from './modules/shared/services/languages.service';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { AccountFeature, IAccount } from './modules/shared/models/IAccount';
import { MenuItem } from './components/top-menu/top-menu.component';
import { menuModules } from './menu-modules';
import { MenuService } from './modules/shared/services/menu.service';
import { HeapAnalyticsService } from './modules/shared/services/heap-analytics.service';
import { AuthRole } from './modules/shared/models/IApiAuthUser';
import { integrations } from './modules/account/settings/integrations/integrations';
import { IntegrationsService } from './modules/account/settings/integrations/integrations.service';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from 'src/environments/environment';
import { AccountsService } from './modules/shared/services/accounts.service';
import { AppService } from './app.service';
import { lastValueFrom } from 'rxjs';
import { AccountWarningsService } from './modules/shared/services/account-warnings.service';
import { AccountWarning } from './modules/shared/models/account-warning.models';
import { Utils } from 'src/app/modules/shared/classes/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  IsLoadingAuth = true;
  user: IAuthUser;
  isAdmin = false;
  isAdminDashboard = false;
  language: ILanguage = null;
  selectedAccount: IAccount = null;
  allowedAccounts: IAccount[];
  public modules: MenuItem[] = menuModules;
  activeMenu: MenuItem;
  isWarningDismissed = false;
  isDemo = false;

  @ViewChild(MatSidenav, { static: true }) sideNav: MatSidenav;
  @ViewChild(MatSidenavContent, { static: true }) sideNavContent: MatSidenavContent;

  constructor(
    private appService: AppService,
    private router: Router,
    private authService: AuthService,
    private accountContext: AccountContextService,
    private accountsService: AccountsService,
    private accountWarnings: AccountWarningsService,
    private heapService: HeapAnalyticsService,
    private integrationsService: IntegrationsService,
    private menuService: MenuService,
    public languagesService: LanguagesService,
    private activatedRoute: ActivatedRoute) {

    this.isWarningDismissed = window.sessionStorage.getItem('warning_dismissed') === 'true';

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isAdminDashboard = event.url.startsWith('/admin');
      }
    });

    this.authService.profile.subscribe(user => this.heapService.setEmail(user?.email));
    this.accountContext.selectedAccount$.subscribe(async account => {
      if (account) {
        const user = await this.authService.getUser();

        if (!(await this.authService.isSysAdmin()) && user.accountIds.length > 1) {
          this.allowedAccounts = await lastValueFrom(this.accountsService.getAccountsFeatures(user.accountIds));
        }

        this.setHeapData(user, account);
        this.setSentryData(user, account);
        this.setBeamerData(user);
      }
    });
  }

  ngOnInit(): void {
    this.appService.appScrollElement = this.sideNavContent.getElementRef();
    this.isDemo = Utils.isDemo();

    this.authService.profile.subscribe(async profile => {
      if (profile != null && this.IsLoadingAuth) {
        this.user = profile;
        this.IsLoadingAuth = false;
        this.isAdmin = await this.authService.isSysAdmin();
      }
    });

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.sideNav.close();
      } else if (event instanceof NavigationEnd) {
        this.activeMenu = menuModules.find(menuItem => this.menuService.isMenuActive(menuItem));
      }
    });

    this.accountContext.selectedAccount$.subscribe(selectedAccount => {
      this.selectedAccount = selectedAccount;
    });

    this.languagesService.language$.subscribe(lang => this.language = lang);
  }

  selectAccount(accountId: number): void {
    this.accountContext.changeAccount(accountId, this.activatedRoute.snapshot);
  }

  setLanguage(langCode: string): void {
    this.languagesService.setLanguage(langCode);
  }

  showAdminDashboard() {
    this.router.navigate(['admin']);
  }


  showAccountDashboard(account: IAccount) {
    this.accountContext.setAccount(account);
  }

  async logout(): Promise<void> {
    await this.authService.logout();
  }

  isDisabledFeature(feature: AccountFeature): boolean {
    return feature ? !this.accountContext.supports(feature) : false;
  }

  routeToInstallationSettings(): void {
    this.router.navigateByUrl('/cm/settings/installation');
  }

  dismissDashboardWarning(): void {
    this.isWarningDismissed = true;
    window.sessionStorage.setItem('warning_dismissed', 'true');
  }

  get dashboardWarning(): Readonly<AccountWarning> { return this.isWarningDismissed ? null : this.accountWarnings.dashboardWarning; }

  private async setHeapData(user: IAuthUser, account: IAccount) {
    const accountProps = {
      AccountName: account.name,
      Currency: account.currency
    };
    let userProps: { [key: string]: any } = {
      Role: user.role
    };
    let eventProps = null;
    const accountRoles = [AuthRole.AccountAdmin, AuthRole.AccountEditor, AuthRole.AccountViewer].map(x => AuthRole[x]);

    if (accountRoles.includes(user.role)) {
      const accountIntegrations = await lastValueFrom(this.integrationsService.getActiveIntegrations());
      const integrationNames = accountIntegrations.map(ai => integrations.find(i => i.id === ai.integrationId).name).join(',');
      userProps = {
        ...userProps,
        ...accountProps,
        Features: account.features.join(','),
        Integrations: integrationNames
      };
    } else {
      eventProps = accountProps;
    }

    this.heapService.setUserProperties(userProps);
    if (eventProps) {
      this.heapService.setEventProperties(eventProps);
    }

  }

  private setSentryData(user: IAuthUser, account: IAccount) {
    if (environment.sentryEnv) {
      Sentry.setUser({ email: user.email });
      Sentry.setTag('account', account.name);
      Sentry.setContext('userExtra', { role: user.role });
    }
  }

  private setBeamerData(user: IAuthUser) {
    (window as any).beamer_config = {
      product_id: 'EDHwcewM27483',
      selector: 'beamer-trigger',
      user_email: user.email
    };
    const script = document.createElement('script');
    script.setAttribute('src', 'https://app.getbeamer.com/js/beamer-embed.js');
    document.body.appendChild(script);
  }

}
