import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Utils } from '../classes/utils';
import { parse as convertJsonToCsv } from 'json2csv';


@Injectable({
  providedIn: 'root'
})
export class CsvExportService {
  exportAsCsv(filePrefix: string, data: any[]) {
    if (data && data.length) {
      const fields = Object.keys(data[0]);
      const opts = { fields };
      const csvData = convertJsonToCsv(data, opts);
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const fileName = `trd_${filePrefix}_${moment().format('YYMMDD_hhmmss')}.csv`;
      Utils.downloadFile(url, fileName);
    }
  }
}
