import { Injectable } from '@angular/core';
import { IAccount } from '../models/IAccount';
import { IListStatusRequest } from '../models/IListRequest';
import { IListResponse } from '../models/IListResponse';
import { IAddAccountRequest } from '../models/IAddAccountRequest';
import { IAddAccountResponse } from '../models/IAddAccountResponse';
import { IAddAccountUserRequest } from '../models/IAddAccountUserRequest';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TokenInterceptorSkipAccountHeader } from '../constants/interceptors-headers';
import { IGetAccountUsersResponse } from '../models/IGetAccountUsersResponse';
import { SetUserConfig, UserConfig } from '../models/UserConfig';


@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  baseUrl: string;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.adminBaseUrl + '/api';
  }

  getAccountsList(request: IListStatusRequest): Observable<IListResponse<IAccount>> {
    const headers = new HttpHeaders().set(TokenInterceptorSkipAccountHeader, '');
    return this.httpClient.post<IListResponse<IAccount>>(`${this.baseUrl}/accounts/list`, request, { headers });
  }

  getAccountFeatures(id: number, noCache = false): Observable<IAccount> {
    const params = noCache ? new HttpParams().set('no-cache', 'true') : {};
    return this.httpClient.get<IAccount>(`${this.baseUrl}/accounts/${id}`, { params });
  }

  getAccountsFeatures(ids: number[]): Observable<IAccount[]> {
    return this.httpClient.get<IAccount[]>(`${this.baseUrl}/accounts/ids`, {
      params: { id: ids.map(x => x.toString()) }
    });
  }

  getAllAccountUsers(): Observable<IGetAccountUsersResponse> {
    return this.httpClient.get<IGetAccountUsersResponse>(`${this.baseUrl}/accounts/users`);
  }

  getUsersConfig(): Observable<UserConfig[]> {
    return this.httpClient.get<UserConfig[]>(`${this.baseUrl}/accounts/users_config`);
  }

  setUserConfig(setUserConfig: SetUserConfig): Observable<void> {
    return this.httpClient.put<void>(`${this.baseUrl}/accounts/users_config`, setUserConfig);
  }

  deleteUserConfig(email: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/accounts/users_config/${encodeURIComponent(email)}`);
  }

  addAccount(request: IAddAccountRequest): Observable<IAddAccountResponse> {
    return this.httpClient.post<IAddAccountResponse>(`${this.baseUrl}/accounts`, request);
  }

  addAccountUser(request: IAddAccountUserRequest): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/accounts/user`, request);
  }

  updateAccountsStatus(ids: number[], status: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.baseUrl}/accounts/change_status`, { accountIds: ids, status });
  }

  resentUserInvitation(email: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.baseUrl}/accounts/user/send_invitation`, null, {
      params: { email }
    });
  }

  removeAccountUser(userId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/accounts/user/${encodeURIComponent(userId)}`);
  }

  addAccountLogin(): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/accounts/login`, null);
  }

  finishContentWizard(): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/accounts/finish_content_wizard`, null);
  }

  sendMonthlyPerformanceReport(email: string): Observable<void> {
    return this.httpClient.get<void>(`${this.baseUrl}/Emails/send_email_monthly?email=${encodeURIComponent(email)}`);
  }

  sendAbmWeeklyReport(email: string): Observable<void> {
    return this.httpClient.get<void>(`${this.baseUrl}/Emails/send_abm_weekly?email=${encodeURIComponent(email)}`);
  }

  performanceDataExists(): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.baseUrl}/Emails/performance_data_exists`);
  }

  abmWeeklyDataExists(): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.baseUrl}/Emails/abm_weekly_data_exists`);
  }
}
