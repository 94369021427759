import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { AccountsService } from '../../modules/shared/services/accounts.service';
import { IAccount } from '../../modules/shared/models/IAccount';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AccountContextService } from 'src/app/modules/shared/services/account-context.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-account-select',
  templateUrl: './account-select.component.html',
  styleUrls: ['./account-select.component.scss']
})
export class AccountSelectComponent implements OnInit, OnDestroy {

  searchTerm: string;
  accounts: IAccount[];
  searchTermChanged = new Subject<string>();
  subscription: Subscription;
  active = true;
  @Output() accountSelected: EventEmitter<IAccount> = new EventEmitter();
  recentAccounts: IAccount[];
  constructor(
    private accountContext: AccountContextService,
    private accountsService: AccountsService,
    private activatedRoute: ActivatedRoute
  ) {
  }
  get status(): 'active' | 'archived' | 'paused' | '' {
    if (this.active) {
      return 'active';
    }
    return '';
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.accountContext.recentAccounts$.subscribe((accounts) => {
      if (accounts && accounts.length && (!this.accounts || this.accounts.length === 0)) {
        this.accounts = accounts;
      }
    });

    this.subscription = this.searchTermChanged
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(newText => {
        this.reload();
      });
  }
  public isRecent(accId: number): boolean {
    return !!this.accountContext?.recentAccounts$?.value?.find(a => a.id === accId);
  }
  private reload() {
    this.accountsService.getAccountsList({
      orderBy: 'user_id',
      direction: 'desc',
      offset: 0,
      limit: 20,
      search: this.searchTerm,
      status: this.status,
      freeTrial: null
    }).subscribe(r => {
      this.accounts = r.items;
    });
  }

  async selectAccount(account: IAccount): Promise<void> {
    const accountFeatures = await this.accountContext.changeAccount(account.id, this.activatedRoute.snapshot);
    this.accountSelected.emit(accountFeatures);
  }
}
