<div class="dialog-wrapper">
    <div class="dialog-title" translate>{{title}}</div>
    <div class="dialog-body">
        <img src="/assets/goals/disconnect.jpg">
        <div class="dialog-msg" *ngIf="data.dialogType === 'ma'">
            Please make sure Trendemon and your marketing automation platform are connected.
            It's a simple 2-minute process which you can do from our
            <a routerLink="/cm/settings/integrations/list" (click)="dialogRef.close(false)">Integration panel</a>.
        </div>
        <div class="dialog-msg" *ngIf="data.dialogType === 'crm'">
            To create CRM-based goals we recommend first connecting your marketing automation.
            If you are not using marketing automation,
            <a target="_blank" href="https://support.trendemon.com/crm-goal">read this post</a>
            about how to alternatively configure CRM-based goals.
        </div>
        <div class="dialog-msg" *ngIf="data.dialogType === 'crm_no_ma'">
            Please make sure Trendemon and your CRM platform are connected.
            It's a simple 2-minute process which you can do from our
            <a routerLink="/cm/settings/integrations/list" (click)="dialogRef.close(false)">Integration panel</a>.
        </div>
    </div>
    <div class="dialog-buttons">
        <button class="app-rounded-btn" (click)="dialogRef.close(true)" translate>{{okText}}</button>
        <button class="app-secondary-rounded-btn" (click)="dialogRef.close(false)" translate>{{cancelText}}</button>
    </div>
</div>