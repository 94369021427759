import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export type GoalsNoMaDialogType = 'ma' | 'crm' | 'crm_no_ma';
export interface GoalsNoMaDialogData {
  dialogType: GoalsNoMaDialogType;
}

@Component({
  selector: 'app-goals-no-ma-dialog',
  templateUrl: './goals-no-ma-dialog.component.html',
  styleUrls: ['./goals-no-ma-dialog.component.scss']
})
export class GoalsNoMaDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<GoalsNoMaDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: GoalsNoMaDialogData) { }

  get title(): string {
    return this.data.dialogType !== 'crm_no_ma'
      ? 'Uh oh, we\'re not connected with your marketing automation'
      : 'Uh oh, we\'re not connected with your CRM';
  }
  get okText(): string { return this.data.dialogType !== 'crm' ? 'Sweet, Take me there' : 'Connect marketing automation'; }
  get cancelText(): string { return this.data.dialogType !== 'crm' ? 'Chill, I\'ll sort it later' : 'Continue with goal setup'; }
}
