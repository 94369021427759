import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { AccountFeature } from 'src/app/modules/shared/models/IAccount';
import { Permission } from 'src/app/auth/auth0-user';
import { LanguagesService } from 'src/app/modules/shared/services/languages.service';
import { AccountContextService } from 'src/app/modules/shared/services/account-context.service';
import { MenuService } from 'src/app/modules/shared/services/menu.service';

export interface MenuItem {
  label: string;
  iconName?: string;
  iconImage?: string;
  route?: string | string[];
  disabled?: boolean;
  permission?: Permission;
  feature?: AccountFeature;
  actionNonPermitted?: 'hide' | 'disable';
  actionFeatureOff?: 'hide' | 'disable';
  childs?: {
    label: string;
    description?: string;
    route: string | string[];
    iconName?: string; // material icon name
    iconOutline?: boolean; // relevant only if iconName is set
    iconImage?: string;
    permission?: Permission;
    feature?: AccountFeature;
    actionFeatureOff?: 'hide' | 'disable';
  }[];
}


@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {
  @Input() modulesList: Array<MenuItem>;
  isWideButtons = false;
  @ViewChildren('menuTrigger') menusTriggers: QueryList<MatMenuTrigger>;

  constructor(
    private languages: LanguagesService,
    private menuService: MenuService,
    private accountContext: AccountContextService
  ) {
    document.addEventListener('mouseup', (event) => {
      this.menusTriggers.forEach(t => {
        t.closeMenu();
        // setTimeout(() => { t.closeMenu(); }, 500);
      });
    });
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') { // escape key maps to keycode `27`
        this.menusTriggers.forEach(t => {
          t.closeMenu();
        });
      }
    });
  }
  ngOnInit(): void {
    this.languages.language$.subscribe(lang =>
      this.isWideButtons = lang.langCode === 'ja');
  }

  clientClicked(): void {
    this.menusTriggers.forEach(t => {
      t.closeMenu();
    });
  }

  isDisabledFeature(feature: AccountFeature): boolean {
    return this.isAccountInitialized
      ? (feature ? !this.accountContext.supports(feature) : false)
      : false;
  }

  isDisabledMenu(feature: AccountFeature): boolean {
    return this.menuService.isMenuDisabled || this.isDisabledFeature(feature);
  }

  get isAccountInitialized(): boolean { return this.accountContext.initialized$.value; }
}
