import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthCallbackComponent } from './auth/auth-callback.component';
import { AuthGuard } from './auth/auth.guard';
import { SysAdminGuard } from './auth/guards/sys-admin.guard';
import { RouteToDefaultGuard } from './auth/guards/route-to-default.guard';
import { AppComponent } from './app.component';


export const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    canActivate: [AuthGuard, RouteToDefaultGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(mod => mod.AdminModule),
    canActivate: [SysAdminGuard]
  },
  {
    path: 'cm',
    loadChildren: () => import('./modules/account/account.module').then(mod => mod.AccountModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'callback',
    component: AuthCallbackComponent
  },
  {
    path: 'login',
    canActivate: [AuthGuard],
    children: []
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes/*, { relativeLinkResolution: 'legacy' }*/)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
