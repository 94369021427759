import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ImageViewerData {
    imageSource: string;
}

@Component({
    selector: 'app-image-viewer',
    template: `<mat-icon mat-dialog-close>cancel</mat-icon><img [src]="data.imageSource">`,
    styles: [
        `
        mat-icon {
            position: absolute;
            top: 3px;
            right: 3px;
            color: #dddddd;
            transition: 400ms
        }

        mat-icon:hover {
            color: #aaaaaa
        }
        `
    ]
})
export class ImageViewerComponent {
    constructor(
        public dialogRef: MatDialogRef<ImageViewerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ImageViewerData
    ) { }
}
