export enum AccountWarningType {
    NoScript,
    NotLive,
    IntegrationError
}

export interface AccountWarning {
    warningType: AccountWarningType;
    description: string;
    instructions: string;
    actionButtonText: string;
    actionButtonRoute: string;
}

export const AccountWarningsConfig: ReadonlyArray<AccountWarning> = Object.freeze([
    Object.freeze({
        warningType: AccountWarningType.NoScript,
        description: 'No Trendemon script was found on website.',
        instructions: 'Visit the Installation section under Configure / Account Setup to resolve.',
        actionButtonText: 'visit installation section',
        actionButtonRoute: '/cm/settings/installation'
    }),
    Object.freeze({
        warningType: AccountWarningType.NotLive,
        description: 'System is in Preview mode.',
        instructions: 'Visit the Installation section under Configure / Account Setup to resolve.',
        actionButtonText: 'visit installation section',
        actionButtonRoute: '/cm/settings/installation'
    }),
    Object.freeze({
        warningType: AccountWarningType.IntegrationError,
        description: 'Issues with {{integration}} integration',
        instructions: 'Visit the integrations section under Configure / Account Setup to resolve.',
        actionButtonText: 'visit Integrations section',
        actionButtonRoute: '/cm/settings/integrations/list'
    }),
]);