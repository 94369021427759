import { PageTag } from 'src/app/modules/shared/models/models';

export type SystemStatus = 'ONLINE' | 'OFFLINE' | 'DEMO';

export type ClientAppType = 'drift' | 'sixsense';

export type DashboardModule = 'accelerated' | 'audiences_n_goals';

export enum ClientAppId {
  Drift = 1,
  SixSense = 2
}

export interface SettingsAccountFeatures {
  name: string;
  supportOwner: string;
  currency: string;
  trackingMA: string;
  usersLimit: number;
  showReportsScreen: boolean;
  showAudienceScreen: boolean;
  showJourneysScreen: boolean;
  identifyJourneys: boolean;
  personalization: boolean;
  showContentWizard: boolean;
  showOrchestration: boolean;
  showOneToWon: boolean;
  showDeals: boolean;
  showAccountSegments: boolean;
  hideCompaniesDashboard: boolean;
  hideCompaniesAssets: boolean;
  abm: boolean;
  showExactCompanyTargetList: boolean;
  abmMinCompanyResolvePages: number;
  abmCountriesIsExclude: boolean;
  abmCountriesToResolve: string[];
  abmAreasIsExclude: boolean;
  abmAreasToResolve: number[];
  abmPagesToExcludeResolve: string[];
  pixelBasedRetargeting: boolean;
  autotags: boolean;
  spa: boolean;
  exploreAssetsRevenue: boolean;
  convertedPerformance: boolean;
  contentHub: boolean;
  survey: boolean;
  reportUpliftProvider: string;
  allAccountImpactAnalysis: boolean;
  trackingMethod: string;
  sendTrdActivityToMa: boolean;
  tryRescanPages: boolean;
  clientApps: ClientAppType[];
  pageviewsLimit: number | null;
  campaignImpsLimit: number | null;
  activeCampaignsLimit: number | null;
  sendMonthlyEmail: boolean;
  readMinScroll: number | null;
  freeTrial: boolean;
  aceSupport: boolean;
  dashboardScreenModules: DashboardModule[];
  useMfa: boolean;
  gaUpliftConfig: string;
  gaStreamConfig: string;
  contentFetchDomain: string;
  shortUrlDomain: string;
  redirectAvoidFlickering: boolean;
  companyJourneySummaryPrompt: string;
}

export interface SettingsAccountDetails {
  systemStatus: SystemStatus;
  isFriendlyUrl: boolean;
  isCodeOnline: boolean;
  spaRegex: string;
}

export interface SettingsAreasReponse {
  areas: SettingsArea[];
  pageTags: PageTag[];
}

export interface SettingsDomainResponse {
  siteDomain: string;
  domains: SettingsDomain[];
}

export interface SettingsIgnoredIps {
  ips: string[];
  masks: string[];
}

export interface SettingsArea {
  id: number;
  name: string;
  url: string;
  pull_content: boolean;
  content_type: 'content' | 'general';
  area_order: number;
  area_by: 'tag' | 'url';
  area_tags: number[];
}

export interface SettingsDomain {
  id: number;
  domain: string;
}

export interface SettingsTags {
  tag: PageTag;
  usedInAreas: number;
  usedInCtas: number;
}

export interface SettingsPersonalization {
  ctaLifeTimeHours: number;
  isGoogleAnalyticsTracking: boolean;
  isGetWeeklyLeadsList: boolean;
  sliderAllocation: string;
  includeOggImage: boolean;
  isFullImageSearch: boolean;
  resizeType: ImageResizeType;
  weekHitsLimit: number;
  contentLifeTimeDays: number;
  useUtm: false;
  utmCode: string;
  excludeTitles: ExcludeTitle[];
  titleTag: string;
  titleClass: string;
  titleId: string;
  autoTagSelectors: string[];
  autoTagLimit: number | null;
}

export enum ImageResizeType {
  NoResize = -1,
  ResizeAndCrop_KeepAspectRatio = 0,
  Resize_NoAspectRatio = 1,
  ResizeFixedWidth_KeepAspectRatio = 2,
}

export interface ExcludeTitle {
  id: number;
  titleTemplate: string;
}

export interface PageDeduplication {
  id: number;
  pattern: string;
}

export interface AccountSummary {
  id: number;
  accountHasSurveys: boolean;
  accountHasLeadGenUnits: boolean;
  accountHasUnits: boolean;
  goalsConfigured: boolean;
  codeInstalled: boolean;
  inWizard: boolean;
  freeTrial: boolean;
  selfStartWizardCompleted: boolean;
  isOfferConfigured: boolean;
  isAudienceConfigured: boolean;
  isOrchestrationConfigured: boolean;
}

export interface IImpactBlock {
  label: string;
  value: string;
  fromTotal: number;
}
