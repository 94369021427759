import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { AuthService } from '../auth.service';

export const SysAdminGuard: CanActivateFn = async () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (await authService.isSysAdmin()) {
    return true;
  }

  router.navigate([await authService.userDefaultRoute()]);
  return false;
}
