import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountGoal, Country, KeyValuePair, RecordsResponse, AccountArea, AbmTargetList, AudienceStatus, AudienceConfig, DataResponse, GoalOrEvent } from '../models/models';
import { environment as config } from '../../../../environments/environment';
import { GetAccountGoalsRequest } from '../models/account-entities-request.models';
import { Utils } from '../classes/utils';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Params } from '@angular/router';
import { IAbmCompanySegment } from '../../account/abm/models';

@Injectable({
  providedIn: 'root'
})
export class AccountEntitiesService {

  constructor(private httpClient: HttpClient) {
  }

  public async getAccountActiveGoals(type: GoalOrEvent | undefined = 'Goal'): Promise<RecordsResponse<AccountGoal>> {
    const request: GetAccountGoalsRequest = {
      status: 'ACTIVE'
    };
    const goalsRes = await this.getAccountGoals(request);
    const activeGoals = goalsRes.data.filter(v => (!type || v.actionType === type));
    return {
      total: activeGoals.length,
      data: activeGoals
    };
  }

  public getAccountAudiences(status = AudienceStatus.active): Observable<AudienceConfig[]> {

    return this.httpClient.get<AudienceConfig[]>(
      `${config.accountBaseUrl}/api/account/audiences`,
      { params: Utils.buildQueryParams({ status }) }
    );
  }

  public getAccountGoals(request: GetAccountGoalsRequest): Promise<RecordsResponse<AccountGoal>> {
    const goals$ = this.httpClient.get<RecordsResponse<AccountGoal>>(
      `${config.adminBaseUrl}/api/goals`,
      { params: Utils.buildQueryParams(request) }
    );
    return lastValueFrom(goals$);
  }

  public getAccountTargetLists(): Observable<RecordsResponse<AbmTargetList>> {
    return this.httpClient.get<RecordsResponse<AbmTargetList>>(
      `${config.accountBaseUrl}/api/abm/target_lists_names`);
  }

  public getCountries(): Observable<Country[]> {
    const lang = localStorage.getItem('lang') || 'en';
    const res = this.httpClient.get<Country[]>(`${config.dictionariesBaseUrl}/api/static/geo`, {
      params: {
        languageCode: lang
      }
    });
    return res;
  }

  public getStates(): Observable<Country[]> {
    const lang = localStorage.getItem('lang') || 'en';
    const res = this.httpClient.get<Country[]>(`${config.dictionariesBaseUrl}/api/static/states`, {
      params: {
        languageCode: lang
      }
    });
    return res;
  }

  public getAccountAreas(accountId?: number): Promise<RecordsResponse<AccountArea>> {
    const params: Params = accountId ? { account_id: accountId.toString() } : {};
    return lastValueFrom(
      this.httpClient.get<RecordsResponse<AccountArea>>(`${config.dictionariesBaseUrl}/api/account/areas`, { params })
    );
  }

  public getAccountCompanies(search: string, limit?: number): Observable<string[]> {
    const params: Params = {
      search,
      ...(limit ? { limit } : {})
    };
    return this.httpClient.get<DataResponse<string[]>>(`${config.insightsBaseUrl}/api/account/companies`, { params }).pipe(
      map(res => res.data)
    );
  }

  public getAccountCompanySegments(): Promise<IAbmCompanySegment[]> {
    return lastValueFrom(
      this.httpClient.get<IAbmCompanySegment[]>(`${config.dictionariesBaseUrl}/api/account/company-segments`)
    );
  }

  public getDevices() {
    return Promise.resolve<KeyValuePair[]>(
      [
        { name: 'desktop', id: '0' },
        { name: 'mobile', id: '1' },
        { name: 'both', id: '2' }
      ]
    );
  }
}
