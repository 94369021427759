import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export interface IConfirmDialogData {
  title: string;
  message: string;
  okButtonText: string;
  okButtonColor: string;
  cancelButtonText: string;
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent {

  okButtonText: string;
  okButtonColor: string;
  cancelButtonText: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData) {

      this.okButtonText = data.okButtonText || _('OK');
      this.okButtonColor = data.okButtonColor || 'primary';
      this.cancelButtonText = data.cancelButtonText || _('Cancel');
    }
}
