import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserNotificationService } from './user-notification.service';
import { ErrorInterceptorSkipHeader } from './constants/interceptors-headers';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private notification: UserNotificationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(ErrorInterceptorSkipHeader)) {
      const headers = req.headers.delete(ErrorInterceptorSkipHeader);
      return next.handle(req.clone({ headers }));
    }

    return next.handle(req)
      .pipe(catchError((err) => {
        if (err instanceof HttpErrorResponse && !req.headers.get('skip')) {
          if (err.status === 401 || err.status === 403) {
            window.location.reload();
            return new Subject<HttpEvent<any>>();
          } else {
            this.notification.notifyError(`CODE ${err.status} ${new URL(req.url).pathname}`, 'Dismiss', err.statusText === 'Unknown Error' ? 'Temporary problem' : err.statusText);
          }
        }
        return throwError(() => err);
      }));
  }
}
