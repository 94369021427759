import { Injectable } from '@angular/core';
import { SettingsAccountDetails, SettingsArea, SettingsDomainResponse, SettingsAreasReponse, SettingsIgnoredIps, SettingsTags, SettingsPersonalization, PageDeduplication, AccountSummary, SettingsAccountFeatures, ClientAppType, ClientAppId } from './models/settings-models';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment as config } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { PageTag, RulesConfig } from '../../shared/models/models';
import { TokenInterceptorSkipAccountHeader } from '../../shared/constants/interceptors-headers';
import { map } from 'rxjs/operators';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) { }

  getAccountFeatures(accountId?: number): Observable<SettingsAccountFeatures> {
    let options = {};
    if (accountId) {
      const headers = new HttpHeaders().set(TokenInterceptorSkipAccountHeader, '');
      const params = { accountId: accountId.toString() };
      options = { headers, params };
    }
    return this.http.get<SettingsAccountFeatures>(`${config.adminBaseUrl}/api/settings/features`, options)
      .pipe(
        // change readMinScroll from null to 0
        map((f: SettingsAccountFeatures) => ({ ...f, readMinScroll: f.readMinScroll ? f.readMinScroll : 0 }))
      );
  }

  saveAccountFeatures(accountId: number, features: SettingsAccountFeatures): Observable<boolean> {
    const headers = new HttpHeaders().set(TokenInterceptorSkipAccountHeader, '');
    const params = { accountId: accountId.toString() };
    return this.http.post<boolean>(`${config.adminBaseUrl}/api/settings/features`, features, { headers, params });
  }

  getAccountDetails(): Observable<SettingsAccountDetails> {
    return this.http.get<SettingsAccountDetails>(`${config.adminBaseUrl}/api/settings/general`);
  }

  saveAccountDetails(params: SettingsAccountDetails): Observable<boolean> {
    return this.http.post<boolean>(`${config.adminBaseUrl}/api/settings/general`, params);
  }

  getAreas(): Observable<SettingsAreasReponse> {
    return this.http.get<SettingsAreasReponse>(`${config.adminBaseUrl}/api/settings/areas`);
  }

  saveAreas(params: SettingsArea[]): Observable<boolean> {
    return this.http.post<boolean>(`${config.adminBaseUrl}/api/settings/areas`, params);
  }

  getDomains(): Observable<SettingsDomainResponse> {
    return this.http.get<SettingsDomainResponse>(`${config.adminBaseUrl}/api/settings/domains`);
  }

  saveDomains(params: SettingsDomainResponse): Observable<boolean> {
    return this.http.post<boolean>(`${config.adminBaseUrl}/api/settings/domains`, params);
  }

  getIgnoredIps(): Observable<SettingsIgnoredIps> {
    return this.http.get<SettingsIgnoredIps>(`${config.adminBaseUrl}/api/settings/ignored_ips`);
  }

  saveIgnoredIps(params: SettingsIgnoredIps): Observable<boolean> {
    return this.http.post<boolean>(`${config.adminBaseUrl}/api/settings/ignored_ips`, params);
  }

  getIgnoredEmails(): Observable<string[]> {
    return this.http.get<string[]>(`${config.adminBaseUrl}/api/settings/ignored_emails`);
  }

  saveIgnoredEmails(params: string[]): Observable<boolean> {
    return this.http.post<boolean>(`${config.adminBaseUrl}/api/settings/ignored_emails`, params);
  }

  getIgnoredAbmDomains(): Observable<string[]> {
    return this.http.get<string[]>(`${config.adminBaseUrl}/api/settings/ignored_abm_domains`);
  }

  saveIgnoredAbmDomains(params: string[]): Observable<boolean> {
    return this.http.post<boolean>(`${config.adminBaseUrl}/api/settings/ignored_abm_domains`, params);
  }

  saveIgnoredAbmDomain(domain: string): Observable<boolean> {
    return this.http.post<boolean>(`${config.adminBaseUrl}/api/settings/ignored_abm_domain`, { domain });
  }

  getPageTags(): Observable<SettingsTags[]> {
    return this.http.get<SettingsTags[]>(`${config.adminBaseUrl}/api/settings/tags`);
  }

  savePageTags(params: PageTag[]): Observable<void> {
    return this.http.post<void>(`${config.adminBaseUrl}/api/settings/tags`, params);
  }

  getPersonalization(): Observable<SettingsPersonalization> {
    return this.http.get<SettingsPersonalization>(`${config.adminBaseUrl}/api/settings/personalization`);
  }

  savePersonalization(params: SettingsPersonalization): Observable<void> {
    return this.http.post<void>(`${config.adminBaseUrl}/api/settings/personalization`, params);
  }

  getExcludePersonalization(): Observable<RulesConfig> {
    return this.http.get<RulesConfig>(`${config.adminBaseUrl}/api/settings/exclude_personalization`);
  }

  saveExcludePersonalization(params: RulesConfig): Observable<void> {
    return this.http.post<void>(`${config.adminBaseUrl}/api/settings/exclude_personalization`, params ?? {});
  }

  getPageDeduplication(): Observable<PageDeduplication[]> {
    return this.http.get<PageDeduplication[]>(`${config.adminBaseUrl}/api/settings/page_dup`);
  }

  savePageDeduplication(params: PageDeduplication[]): Observable<void> {
    return this.http.post<void>(`${config.adminBaseUrl}/api/settings/page_dup`, params);
  }

  getClientAppItems(clientApp: ClientAppType): Observable<string[]> {
    const params: Params = {
      clientApp: this.getClientAppId(clientApp)
    };
    return this.http.get<string[]>(`${config.adminBaseUrl}/api/settings/client_app_items`, { params });
  }

  getClientAppId(clientApp: ClientAppType): ClientAppId {
    for (const item in ClientAppId) {
      if (item.toLowerCase() === clientApp) {
        return Number(ClientAppId[item]);
      }
    }
  }

  getTrendemonTag(accountId): string {
    return `<!-- TrenDemon Code -->
   <script type="text/javascript" id="trd-flame-load">
   TRD_ACC_ID = ${accountId};
    (function (w, d) {
         function go() {
            var trdti = setInterval(function () {
            if (document.readyState === "complete") {
                var bi = document.createElement("script"); bi.type = "text/javascript"; bi.async = true;
                bi.src = "${config.engagmentPreviewBaseUrl}/tag/trends.min.js";
                bi.id  = "trdflame";
                var s  = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(bi, s);
                clearInterval(trdti);
                }
           }, 500);
        };
        go();
     }(window, document));
  </script>
<!-- End of TrenDemon Code -->`;
  }

  getTrendemonSyncTag(accountId): string {
    return `<!-- TrenDemon Code -->
   <script type="text/javascript" id="trd-flame-load">
   TRD_ACC_ID = ${accountId};
    (function (w, d) {
      var bi = document.createElement("script"); bi.type = "text/javascript"; bi.async = true;
      bi.src = "${config.engagmentPreviewBaseUrl}/tag/trends.min.js";
      bi.id  = "trdflame";
      var s  = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(bi, s);
     }(window, document));
  </script>
<!-- End of TrenDemon Code -->`;
  }

  getTrendemonSyncFastTag(accountId): string {
    return `<!-- TrenDemon Code -->
<script type="text/javascript" id="trd-tag-script" src="${config.engagmentPreviewBaseUrl}/trends-tag/${accountId}"></script>
<!-- End of TrenDemon Code -->`;
  }

  getAccountSummary(): Observable<AccountSummary> {
    return this.http.get<AccountSummary>(`${config.adminBaseUrl}/api/settings/summary`);
  }

  getAccountIsScriptLive(): Observable<boolean> {
    const params = new HttpParams().set('no-cache', 'true');
    return this.http.get<{ isScriptLive: boolean }>(`${config.accountBaseUrl}/api/account/is_script_live`, { params })
      .pipe(
        map((x: { isScriptLive: boolean }) => x.isScriptLive)
      );
  }
}
