import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

type NotificationAction = 'Dismiss' | 'Reload';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService {
  constructor(private snackBar: MatSnackBar, private tran: TranslateService) {
  }
  notifyError(msg: string, action: NotificationAction = 'Dismiss', title?: string) {
    this.notify('error', msg, action, title);

  }
  error(msg: Error, title?: string) {
    this.notify('error', String(msg), 'Dismiss', title);

  }
  notifySuccess(msg: string, title?: string) {
    this.notify('info', msg, 'Dismiss', title || 'Success');
  }

  notify(severity: 'debug' | 'info' | 'warn' | 'error', msg: string, action: NotificationAction, title?: string) {
    this.openSnackBar(severity, msg, action, title);
  }
  actionClicked(action: NotificationAction) {
    if (action === 'Dismiss') {
    }
    if (action === 'Reload') {
      window.location.reload();
    }
  }

  openSnackBar(severity: 'debug' | 'info' | 'warn' | 'error', msg: string, action: NotificationAction, title?: string) {
    const durationSec = severity === 'warn' || severity === 'error' ? 5 : 3;
    this.snackBar
      .open(`${title} - ${msg}`, this.tran.instant(action), { duration: durationSec * 1000, verticalPosition: 'top', panelClass: severity })
      .onAction().subscribe(() => {
        this.actionClicked(action);
      });
  }
}
