<ng-container>
  <div class="container">
    <mat-form-field>
      <input matInput type="text" placeholder="Account name.." [(ngModel)]="searchTerm"
        (ngModelChange)='searchTermChanged.next($event)'>
      <button [style.visibility]="searchTerm? 'visible': 'hidden'" matSuffix mat-icon-button
        aria-label="Clear" (click)="searchTerm=''">
        <mat-icon>close</mat-icon>
      </button>
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <div>
      <mat-checkbox [(ngModel)]="active" (ngModelChange)='searchTermChanged.next($event)'>Active </mat-checkbox>
    </div>
    <div *ngFor="let account of accounts" class="account-row account-row-{{account.status.toLowerCase()}}">
      <mat-icon class="icon_recent" *ngIf="isRecent(account.id)">history</mat-icon>
      <span class="account-status-{{account.status.toLowerCase()}}" [matTooltip]="account.status"></span>
      <a mat-button (click)="selectAccount(account)">{{account.name}}
      </a>
    </div>
  </div>
</ng-container>
