import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteConfirmDialogComponent } from '../components/delete-confirm-dialog/delete-confirm-dialog.component';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from '../components/alert-dialog/alert-dialog.component';
import { LoadingDialogComponent } from '../components/loading-dialog/loading-dialog.component';
import { ImageEditorDialogComponent } from '../components/image-editor-dialog/image-editor-dialog.component';
import { lastValueFrom } from 'rxjs';
import { ImageViewerComponent } from '../components/image-viewer.component';
import { GoalsNoMaDialogComponent, GoalsNoMaDialogData, GoalsNoMaDialogType } from '../../account/settings/account-settings-goals-add/goals-no-ma-dialog/goals-no-ma-dialog.component';
import { Router } from '@angular/router';
import { ShowContentDialogComponent } from '../components/show-content-dialog/show-content-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class ModalsService {

    constructor(private dialog: MatDialog, private router: Router) { }

    public async ConfirmDelete(message: string): Promise<boolean> {
        const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
            width: '400px', data: { message }
        });
        return await lastValueFrom(dialogRef.afterClosed());
    }

    public async Confirm(message: string, title?: string, okButtonText?: string, okButtonColor?: string, cancelButtonText?: string): Promise<boolean> {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '400px', data: { message, title, okButtonText, okButtonColor, cancelButtonText }
        });
        return await lastValueFrom(dialogRef.afterClosed());
    }

    public async Alert(message: string, title?: string, okButtonText?: string, okButtonColor?: string, width: string = '400px'): Promise<void> {
        const dialogRef = this.dialog.open(AlertDialogComponent, {
            width, data: { message, title, okButtonText, okButtonColor }
        });
        await lastValueFrom(dialogRef.afterClosed());
    }

    public Loading(message?: string, title?: string): MatDialogRef<LoadingDialogComponent> {
        return this.dialog.open(LoadingDialogComponent, { width: '200px', data: { message, title } });
    }

    /**
     * shows a 'no marketing automation' dialog
     *
     *  @returns true: app was redirected to integartions screen, false: app not redirected
     */
    public async NoMaDialog(dialogType: GoalsNoMaDialogType): Promise<boolean> {
        const dialogData: GoalsNoMaDialogData = { dialogType };
        const dialogRef = this.dialog.open(GoalsNoMaDialogComponent, { data: dialogData, hasBackdrop: true });
        const dialogRes = await lastValueFrom(dialogRef.afterClosed());
        if (dialogRes) {
            this.router.navigateByUrl('/cm/settings/integrations/list');
            return true;
        }
        return false;
    }

    public async ImageViewer(imageSource: string): Promise<void> {
        const dialogRef = this.dialog.open(ImageViewerComponent, {
            panelClass: 'dialog-thin-padding',
            data: { imageSource }
        });
        await lastValueFrom(dialogRef.afterClosed());
    }

    public async ImageEditor(imageUrl: string): Promise<Blob> {
        const dialogRef = this.dialog.open(ImageEditorDialogComponent, {
            width: '1200px', height: '90%', hasBackdrop: true, data: { imageUrl }
        });
        return await lastValueFrom(dialogRef.afterClosed());
    }

    public async ShowContent(content: string, title?: string, text?: string): Promise<void> {
        const dialogRef = this.dialog.open(ShowContentDialogComponent, {
            data: { content, title, text }
        });
        await lastValueFrom(dialogRef.afterClosed());
    }
}
