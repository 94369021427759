import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-loader',
    template: `<img style="height: {{height}}" src="/assets/trendemon_inner_loader.svg" />`,
    styles: [
        `
        :host {
            display: flex;
            justify-content: center;
            background-color: white;
        }
        `
    ]
})
export class LoaderComponent {
    @Input() height = '60px';
}
