import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { AuthService } from './auth.service';

export const AuthGuard: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot) => {
  const authService = inject(AuthService);

  const isAuthenticated = await authService.getIsAuthenticated(true);
  if (isAuthenticated) {
    return true;
  }

  await authService.login(state.url);
  return false;
}
