import { User } from '@auth0/auth0-spa-js';
import { IAuthUser } from '../modules/shared/models/IAuthUser';

export class Auth0User implements IAuthUser {
  private user: User;

  constructor(user: User) {
    this.user = user;
  }

  get rawUser(): any {
    return this.user;
  }
  get name(): string {
    return this.user.name;
  }
  get email(): string {
    return this.user.email;
  }
  get picture(): string {
    return this.user.picture;
  }
  get role(): string {
    const rolesArray = this.user['https://trendemon.com/claims/roles'];
    return Array.isArray(rolesArray) && rolesArray.length > 0 ? rolesArray[0] : undefined;
  }
  get accounts(): string[] {
    return this.user['https://trendemon.com/claims/groups'].filter(x => x.startsWith('ac_'));
  }
  get accountIds(): number[] {
    return this.getIdsFromGroupsList(this.accounts);
  }

  private getIdsFromGroupsList(groups: string[]): number[] {
    return groups.map(x => this.getIdFromGroupName(x)).filter(x => !isNaN(x));
  }

  private getIdFromGroupName(groupName: string): number {
    const groupSplit = groupName.split('_', 3);
    return Number(groupSplit[1]); // return Nan if it's no a number
  }
}
export type Permission = 'system:admin' | 'network:admin' | 'account:admin' | 'account:editor' | 'account:viewer';
