import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

export interface ILanguage {
  langCode: string;
  flagCode: string;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {
  private LANGUAGE_KEY = 'lang';
  language$ = new BehaviorSubject<ILanguage>(null);

  languages: ILanguage[] = [
    {
      langCode: 'en',
      flagCode: 'us',
      title: 'English'
    },
    {
      langCode: 'ja',
      flagCode: 'jp',
      title: '日本語'
    },
  ];

  constructor(private translateService: TranslateService) {
    this.initLanguage();
  }

  setLanguage(langCode: string) {
    const lang = this.languages.find(x => x.langCode === langCode);
    if (lang) {
      this.translateService.use(langCode);
      localStorage.setItem(this.LANGUAGE_KEY, langCode);
      this.language$.next(lang);
    }
  }

  private initLanguage() {
    const langCode = localStorage.getItem(this.LANGUAGE_KEY) || 'en';
    this.setLanguage(langCode);
  }
}
