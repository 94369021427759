export interface IApiAuthUser {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    name: string;
    role: AuthRole;
    lastLogin: number;
    picture: string;
    useMfa: boolean;
}

export enum AuthRole {
    SystemAdmin = 1,
    NetworkAdmin = 2,
    AccountAdmin = 3,
    AccountEditor = 4,
    AccountViewer = 5
}
