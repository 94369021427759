import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../auth.service';
import { AccountContextService } from 'src/app/modules/shared/services/account-context.service';

export const RouteToDefaultGuard: CanActivateFn = async () => {
  const authService = inject(AuthService);
  const selectionsService = inject(AccountContextService);

  const isAuthenticated = await authService.getIsAuthenticated();
  if (!isAuthenticated) {
    return false;
  }

  await selectionsService.routeToDefaultByRole();
  return false;
}
