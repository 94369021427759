import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HeapAnalyticsService {
    private isLoaded = false;
    private lastEmail: string;

    setEmail(email: string) {
        if (this.load()) {
            if (email && email !== this.lastEmail) {
                (window as any).heap.identify(email, 'email');
                this.lastEmail = email;
            }
        }
    }

    setUserProperties(properties: { [key: string]: string | number }) {
        if (this.load()) {
            (window as any).heap.addUserProperties(properties);
        }
    }

    setEventProperties(properties: { [key: string]: string | number }) {
        if (this.load()) {
            (window as any).heap.addEventProperties(properties);
        }
    }

    private load() {
        if (!this.isLoaded) {
            if (environment.heapAppId) {
                (window as any).heap.load(environment.heapAppId);
                this.isLoaded = true;
            }
        }
        return this.isLoaded;
    }

}
