import { Injectable } from '@angular/core';
import { Router, Event, NavigationEnd, IsActiveMatchOptions } from '@angular/router';
import { MenuItem } from 'src/app/components/top-menu/top-menu.component';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    private menuDisabledRoute = ['/cm/self-start'];
    private privateIsMenuDisabled = false;
    public get isMenuDisabled() { return this.privateIsMenuDisabled; }

    constructor(private router: Router) {
        router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.privateIsMenuDisabled = this.menuDisabledRoute.includes(event.urlAfterRedirects);
            }
        });
    }

    public isMenuActive(menuItem: MenuItem): boolean {
        if (menuItem.childs) {
            return menuItem.childs.filter(child => this.isRouteActive(child.route)).length > 0;
        } else {
            return this.isRouteActive(menuItem.route);
        }
    }

    public isRouteActive(route: string | string[]): boolean {
        const options: IsActiveMatchOptions = {
            matrixParams: 'ignored',
            queryParams: 'ignored',
            paths: 'subset',
            fragment: 'ignored'
        };
        if (Array.isArray(route)) {
            return route.filter(r => this.router.isActive(r, options)).length > 0;
        } else {
            return this.router.isActive(route, options);
        }
    }
}
