import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpCacheService {
  private requests: Map<string, { expiresAt: number; data: HttpResponse<any> }> = new Map();
  constructor() {
    document.addEventListener('settingsChanged', () => {
      this.invalidateCache();
    });

    setInterval(() => {
      const toDelete = [];
      this.requests.forEach((entry, key) => {
        if (this.isExpired(entry)) {
          toDelete.push(key);
        }
      });
      toDelete.forEach((key) => this.requests.delete(key));
    }, 30 * 1000);
  }
  put(url: string, response: HttpResponse<any>, secToExpire: number = 300): void {
    this.requests.set(url, { expiresAt: new Date().getTime() + (secToExpire * 1000), data: response });
  }

  get(url: string): HttpResponse<any> | undefined {
    const item = this.requests.get(url);
    if (item) {
      if (!this.isExpired(item)) {
        return item.data;
      }
      this.requests.delete(url);
    }
    return undefined;
  }
  private isExpired(entry: { expiresAt: number; data: HttpResponse<any> }): boolean {
    return entry.expiresAt <= new Date().getTime();
  }
  invalidateCache(): void {
    this.requests.clear();
  }
}
