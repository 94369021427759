import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.sentryEnv) {
  Sentry.init({
    dsn: 'https://2ea27f9e9a564080b5d3d84c7d47b297@o456764.ingest.sentry.io/5450131',
    environment: environment.sentryEnv,
    release: environment.sentryRelease,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      // new Sentry.Replay({ maskAllText: false }) // causes error in drag&drop
    ],
    tracePropagationTargets: ['localhost', '/^\//'],
    maxValueLength: 1000,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    ignoreErrors: [
      'Non-Error exception captured',
      'Cannot read properties of null (reading \'close\')'
    ],
    // beforeSend: event => {
    //   return event;
    // }
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
