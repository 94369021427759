import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { MenuItem } from 'src/app/components/top-menu/top-menu.component';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MenuService } from '../modules/shared/services/menu.service';

@Directive({
    selector: '[appActiveMenuItem]'
})
export class ActiveMenuItemDirective implements OnInit {
    @Input('appActiveMenuItem') menuItem: MenuItem;
    activeClass = 'active';

    constructor(private el: ElementRef, private router: Router, private menuService: MenuService) { }
    ngOnInit(): void {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                if (this.menuService.isMenuActive(this.menuItem)) {
                    this.el.nativeElement.classList.add(this.activeClass);
                } else {
                    this.el.nativeElement.classList.remove(this.activeClass);
                }
            });
    }
}
